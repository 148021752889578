import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import {
  setFindProps,
  checkboxChange,
  getCarModel,
  resetCarModel,
  getMotorsForMakeModelFuelBody,
  getBodyColorForLocale,
  getInteriorColorForLocale,
  customSetFindProps,
  removeCarModel,
  getUpholsteryForLocale,
  getConditionForLocale,
} from "../../store/actions";
import { emissionClass, emissionSticker, perimetarOptions } from "./data";
import { previousOwnerCount } from "../../data";
import { useTranslation } from "react-i18next";
import {
  thousandSeparatorWithPeriod,
  sortByNumeric,
  acceptOnlyNumbers,
} from "../../lib/utils";
import _Slider from "../../components/slider/index";
import history from "../../lib/history";
import CheckboxField from "../../components/checkbox-field/index";
import Accordion from "../../components/accordion/index";
import Button from "../../components/button/index";
import FormControl from "../../components/form-control/index";
import FilterSection from "../../components/filter-section";
import PopularBrands from "../../components/popular-brands";
import ColorPicker from "../../components/color-picker";
import Radio from "../../components/radio";
import Marker from "../../components/marker";
import Delete from "../../assets/shapes/delete.svg";
import Edit from "../../assets/shapes/edit.svg";
import "./index.css";

const defaultProps = {
  center: {
    lat: 10.99835602,
    lng: 77.01502627,
  },
  zoom: 11,
};

let circle;

const AdvancedSearch = (props) => {
  const {
    dispatch,
    makeWithAll,
    carModelWithAll,
    find_data,
    fuel_type,
    bodyTypeWithAll,
    interiorcolor,
    condition,
    motors,
    upholstery,
    doors,
    seats,
    transmission,
    bodyTypeCount,
    bodyColors,
    idlocale,
  } = props;
  const { t } = useTranslation(["common, advancedSearch"]);
  const [metallicColor, setMetallicColor] = useState(false);
  const [nonSmokingVehicle, setNonSmokingVehicle] = useState(false);
  const [damagedVehicle, setDamagedVehicle] = useState(false);
  const [indicateVat, setIndicateVat] = useState(false);
  const [adsWithPictures, setAdsWithPictures] = useState(false);
  const [guarantee, setGuarantee] = useState(false);
  const [particulateFilter, setParticulateFilter] = useState(false);
  const [catalyticConverter, setCatalyticConverter] = useState(false);
  const [huau, setHuau] = useState(false);
  const [fullServiceHistory, setFullServiceHistory] = useState(false);
  const language = localStorage.getItem("i18nextLng");
  const [currentPosition, setCurrentPosition] = useState({ lat: 0, lng: 0 });
  const [useCurrentPosition, setUseCurrentPosition] = useState(false);
  const [viewMakeModelAndType, setViewMakeModelAndType] = useState([
    { make: "", model: "", bodyType: "", view: true },
  ]);

  const apiIsLoaded = (map, maps) => {
    circle = new maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.3,
      map,
      center: currentPosition,
      radius: perimetarOptions.find((item) => item.radius === find_data.radius)
        ?.value,
    });
  };

  const handleSearch = () => {
    history.push("/results");
  };

  const handleSliderData = (label, value) => {
    const labels = [label + "From", label + "To"];
    value.forEach((item, index) => {
      dispatch(setFindProps(labels[index], item));
    });
  };

  const checkboxChangeHandler = (arrayName, id, propName, key) => {
    dispatch(checkboxChange(arrayName, id, propName));
    if (find_data?.idtransmission === id) {
      dispatch(setFindProps(key, null));
    } else if (find_data?.idFuelType === id) {
      dispatch(setFindProps(key, null));
    } else {
      dispatch(setFindProps(key, id));
    }
  };

  // get motors && get seats
  useEffect(() => {
    if (
      find_data.idMake &&
      find_data.idModel &&
      find_data.idFuelType &&
      find_data.idBodyType
    ) {
      dispatch(
        getMotorsForMakeModelFuelBody([
          find_data.idMake[0],
          find_data.idModel[0],
          find_data.idFuelType,
          find_data.idBodyType,
        ])
      );
    }
  }, [
    find_data.idMake,
    find_data.idModel,
    find_data.idFuelType,
    find_data.idBodyType,
  ]);

  useEffect(() => {
    dispatch(getBodyColorForLocale());
    dispatch(getInteriorColorForLocale());
    dispatch(getUpholsteryForLocale());
    dispatch(getConditionForLocale());
  }, [idlocale]);

  useEffect(() => {
    if (
      !(find_data?.idMake?.length > 0 && find_data.idMake[0] !== null) &&
      !(find_data?.idBodyType?.length > 0)
    ) {
      dispatch(getCarModel(0, 0));
      dispatch(customSetFindProps("idMake", [null]));
      dispatch(customSetFindProps("idModel", [null]));
      dispatch(customSetFindProps("idBodyType", []));
    } else {
      const newState = [];
      find_data.idMake.forEach((_, index) => {
        let make = null;
        let model = null;
        let bodyType = null;
        if (
          Array.isArray(find_data.idMake) &&
          acceptOnlyNumbers(find_data.idMake[index])
        ) {
          const item = makeWithAll.find(
            (item) => item.idmake === find_data.idMake[index]
          );
          make = item.name;
        }
        if (
          Array.isArray(find_data.idModel) &&
          acceptOnlyNumbers(find_data.idModel[index])
        ) {
          const item = (carModelWithAll[index] ?? []).find(
            (item) => item.idmodel === find_data.idModel[index]
          );
          model = item?.name ? item.name : null;
        } else if (!Array.isArray(find_data.idModel)) {
          dispatch(customSetFindProps("idModel", [null]));
        }
        if (
          Array.isArray(find_data.idBodyType) &&
          acceptOnlyNumbers(find_data.idBodyType[index])
        ) {
          const item = bodyTypeWithAll.find(
            (item) => item.idbodytype == find_data.idBodyType[index]
          );
          bodyType = item.name[language ? language.toUpperCase() : "EN"];
        }

        newState[index] = { make, model, bodyType, view: true };
      });
      setViewMakeModelAndType(() => newState);
    }
    dispatch(getInteriorColorForLocale());
    dispatch(getBodyColorForLocale());
    dispatch(getUpholsteryForLocale());
    dispatch(getConditionForLocale());
    if (find_data?.latitude && find_data?.longitude) {
      setUseCurrentPosition(true);
      setCurrentPosition({
        lat: find_data.latitude,
        lng: find_data.longitude,
      });
    }
  }, []);
  const setFindDataHandler = (key, value, type) => {
    dispatch(setFindProps(key, value === -1 ? null : value, type));
  };

  const checkboxHandler = (key, value) => {
    if (key === "metallicColor") {
      dispatch(setFindProps(key, value));
      setMetallicColor(value);
    }
    if (key === "damagedVehicle") {
      dispatch(setFindProps(key, value));
      setDamagedVehicle(value);
    }
    if (key === "nonSmokingVehicle") {
      dispatch(setFindProps(key, value));
      setNonSmokingVehicle(value);
    }
    if (key === "indicateVat") {
      dispatch(setFindProps(key, value));
      setIndicateVat(value);
    }
    if (key === "adsWithPictures") {
      dispatch(setFindProps(key, value));
      setAdsWithPictures(value);
    }
    if (key === "guarantee") {
      dispatch(setFindProps(key, value));
      setGuarantee(value);
    }
    if (key === "particulateFilter") {
      dispatch(setFindProps(key, value));
      setParticulateFilter(value);
    }
    if (key === "catalyticConverter") {
      dispatch(setFindProps(key, value));
      setCatalyticConverter(value);
    }
    if (key === "huau") {
      dispatch(setFindProps(key, value));
      setHuau(value);
    }
    if (key === "fullServiceHistory") {
      dispatch(setFindProps(key, value));
      setFullServiceHistory(value);
    }
    if (key === "vehiclelocation") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            setCurrentPosition({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setFindDataHandler("place", "");
            setFindDataHandler("zipcode", "");
            setFindDataHandler("latitude", position.coords.latitude);
            setFindDataHandler("longitude", position.coords.longitude);
            setFindDataHandler("radius", perimetarOptions[1].radius);
            setUseCurrentPosition((prevState) => !prevState);
          },
          function (error) {
            console.log(error);
          }
        );
      }
    }
  };

  const handleDateChange = (date) => {
    const transformedDate = convertUTCDateToLocalDate(date).toISOString();
    setFindDataHandler("adOnlineSince", transformedDate.split("T")[0]);
  };

  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    return newDate;
  }

  //ReSort transmission data by ID (reason: on lang change checkboxes swap positions)

  const sortedTransmissionByIds = sortByNumeric(
    transmission,
    "asc",
    "idtransmission"
  );

  const addMakeOrModel = (key, value, index, name) => {
    value = value === -1 ? null : value;
    if (key === "idMake") {
      if (value !== null) {
        dispatch(getCarModel(value, index));
      }
      dispatch(resetCarModel(index));
      const newMake = [...find_data.idMake];
      newMake[index] = value;
      const newModel = [...find_data.idModel];
      newModel[index] = null;
      dispatch(customSetFindProps("idMake", newMake));
      dispatch(customSetFindProps("idModel", newModel));
      setViewMakeModelAndType((prevState) => {
        const newState = [...prevState];
        newState[index].make = name;
        newState[index].model = "";
        return newState;
      });
    } else if (key === "idModel") {
      const newModel = [...find_data.idModel];
      newModel[index] = value;
      dispatch(customSetFindProps("idModel", newModel));
      setViewMakeModelAndType((prevState) => {
        const newState = [...prevState];
        newState[index].model = name;
        return newState;
      });
    } else if (key === "idBodyType") {
      let newBodyType = [...find_data.idBodyType];
      dispatch(customSetFindProps("idBodyType", newBodyType));
      newBodyType[index] = newBodyType[index] === value ? null : value;
      dispatch(customSetFindProps("idBodyType", newBodyType));
      setViewMakeModelAndType((prevState) => {
        const newState = [...prevState];
        const bodyTypeObject = Object.values(bodyTypeWithAll).find(
          (item) => item.idbodytype == newBodyType[index]
        );
        newState[index].bodyType = bodyTypeObject
          ? bodyTypeObject.name[language ? language.toUpperCase() : "EN"]
          : "";
        return newState;
      });
    }
  };

  const addAnotherVehicle = () => {
    dispatch(customSetFindProps("idMake", [...find_data.idMake, null]));
    dispatch(customSetFindProps("idModel", [...find_data.idModel, null]));
    dispatch(customSetFindProps("idBodyType", [...find_data.idBodyType, null]));
    setViewMakeModelAndType((prevState) => [
      ...prevState,
      { make: "", model: "", bodyType: "", view: true },
    ]);
  };

  const deleteVehicle = (indexPosition) => {
    dispatch(removeCarModel(indexPosition));
    const newMake = find_data.idMake.filter(
      (_, index) => index !== indexPosition
    );
    const newModel = find_data.idModel.filter(
      (_, index) => index !== indexPosition
    );
    const newBodyType = find_data.idBodyType
      ? find_data.idBodyType.filter((_, index) => index !== indexPosition)
      : [];
    dispatch(customSetFindProps("idMake", newMake));
    dispatch(customSetFindProps("idModel", newModel));
    dispatch(customSetFindProps("idBodyType", newBodyType));
    setViewMakeModelAndType((prevState) => {
      const newState = [...prevState];
      delete newState[indexPosition];
      return newState.filter((item) => item?.view !== null);
    });
  };

  return (
    <div className="advanced-search">
      <header>
        <h3>{t("common:advanced-search")}</h3>
        <p>{t("advancedSearch:description")}</p>
      </header>
      <main>
        {find_data?.idMake?.length > 0 &&
          find_data?.idMake.map((_, index) => (
            <section key={index} className="filter-section">
              <div className="filter-section-title d-flex flex-justify-between">
                <h5 className="d-flex flex-align-center">
                  <span className="make-model-circle">{index + 1}</span>
                  {find_data?.idMake[index] || find_data?.idModel[index]
                    ? `${
                        find_data?.idMake[index]
                          ? viewMakeModelAndType[index]?.make
                          : ""
                      } ${
                        Array.isArray(find_data?.idModel) &&
                        find_data?.idModel[index]
                          ? viewMakeModelAndType[index]?.model
                          : ""
                      } ${
                        Array.isArray(find_data?.idBodyType) &&
                        find_data.idBodyType[index]
                          ? viewMakeModelAndType[index]?.bodyType
                          : ""
                      }`
                    : t("advancedSearch:select-make-model-type")}
                </h5>
                <div className="actions">
                  <span>
                    <img
                      onClick={() =>
                        setViewMakeModelAndType((prevState) => {
                          const newState = [...prevState];
                          newState[index].view = !newState[index]?.view;
                          return [...newState];
                        })
                      }
                      src={Edit}
                      alt="edit"
                    />
                  </span>
                  {find_data.idMake.length !== 1 && (
                    <span>
                      <img
                        onClick={() => deleteVehicle(index)}
                        src={Delete}
                        alt="delete"
                      />
                    </span>
                  )}
                </div>
              </div>
              <div className="make-model-bodytype-controls">
                {viewMakeModelAndType[index]?.view && (
                  <>
                    <div className="make-model-popular-brands-container">
                      <div className="make-model-inputs">
                        <FormControl
                          inputType="select"
                          handleChange={(_, newValue) =>
                            addMakeOrModel(
                              "idMake",
                              newValue ? newValue.idmake : null,
                              index,
                              newValue ? newValue.name : null
                            )
                          }
                          data={makeWithAll}
                          value={makeWithAll.find(
                            (item) => item.idmake === find_data.idMake[index]
                          )}
                          placeholder={t("common:car-brand")}
                        />
                        <FormControl
                          inputType="select"
                          handleChange={(_, newValue) =>
                            addMakeOrModel(
                              "idModel",
                              newValue ? newValue.idmodel : null,
                              index,
                              newValue ? newValue.name : null
                            )
                          }
                          data={
                            Array.isArray(carModelWithAll[index])
                              ? carModelWithAll[index]
                              : []
                          }
                          value={(Array.isArray(carModelWithAll[index])
                            ? carModelWithAll[index]
                            : []
                          ).find(
                            (item) =>
                              item.idmodel ===
                              (Array.isArray(find_data.idModel) &&
                              find_data.idModel[index] !== undefined
                                ? find_data.idModel[index]
                                : null)
                          )}
                          placeholder={t("common:model")}
                        />
                      </div>
                      <PopularBrands
                        modelIndex={index}
                        message={t("advancedSearch:or-click")}
                        handler={addMakeOrModel}
                      />
                    </div>
                    <FilterSection className="vehicle-type-filter-section">
                      <div className="vehicle-types">
                        {bodyTypeWithAll.length > 0 &&
                          bodyTypeWithAll.map((item, bodyTypeIndex) => {
                            if (!!item.src) {
                              return (
                                <div
                                  key={bodyTypeIndex}
                                  onClick={() => {
                                    addMakeOrModel(
                                      "idBodyType",
                                      item.idbodytype,
                                      index
                                    );
                                  }}
                                  className={`vehicle-type ${
                                    [find_data?.idBodyType?.[index]]?.includes(
                                      item.idbodytype
                                    )
                                      ? "vehicle-type-checked"
                                      : ""
                                  }`}
                                >
                                  <img src={item.src} />
                                  <span>
                                    <p>
                                      {
                                        item.name[
                                          language
                                            ? language.toUpperCase()
                                            : "EN"
                                        ]
                                      }
                                    </p>
                                    <p>
                                      (
                                      {thousandSeparatorWithPeriod(
                                        bodyTypeCount.find(
                                          (subItem) =>
                                            subItem.idbodytype ===
                                            item.idbodytype
                                        )?.vehiclescount ?? 0
                                      )}
                                      )
                                    </p>
                                  </span>
                                </div>
                              );
                            }
                          })}
                      </div>
                    </FilterSection>
                  </>
                )}
              </div>
              {index === find_data.idMake.length - 1 && (
                <Button
                  color="transparent"
                  label={`+ ${t("advancedSearch:add-another-vehicle")}`}
                  className="addanothervehicle"
                  click={addAnotherVehicle}
                />
              )}
            </section>
          ))}
        <FilterSection title={null} className="filter-location-section">
          <Radio
            id="location-distance"
            className="radio-button-location-distance"
            name="location"
            label={t("advancedSearch:current-location-distance")}
            labelClass="filter-location-label"
            changeHandler={() => checkboxHandler("vehiclelocation")}
            checked={useCurrentPosition}
          />
          {useCurrentPosition && (
            <>
              <div className="google-map">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: "" }}
                  defaultCenter={currentPosition}
                  defaultZoom={defaultProps.zoom}
                  zoomControl={false}
                  onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
                >
                  <Marker lat={currentPosition.lat} lng={currentPosition.lng} />
                </GoogleMapReact>
              </div>
              <FormControl
                label={t("advancedSearch:perimetar")}
                inputType="select"
                placeholder={t("common:all")}
                data={perimetarOptions}
                className={"mb-1"}
                value={perimetarOptions.find(
                  (item) => item.radius === find_data.radius
                )}
                handleChange={(_, newValue) => {
                  setFindDataHandler("radius", newValue.radius);
                  circle.setRadius(Number(newValue.value));
                }}
              ></FormControl>
            </>
          )}
          <div className="manual-location contact-form">
            <FormControl
              value={find_data?.place ? find_data.place : ""}
              inputType="textfield"
              label={t("profile:place")}
              type="text"
              placeholder="Type"
              handleChange={(event) =>
                setFindDataHandler("place", event.target.value)
              }
              disabled={useCurrentPosition}
            />
            <FormControl
              value={find_data?.zipcode ? find_data.zipcode : ""}
              inputType="textfield"
              label={t("profile:zip-code")}
              type="text"
              placeholder="Type"
              handleChange={(event) =>
                setFindDataHandler("zipcode", event.target.value)
              }
              disabled={useCurrentPosition}
            />
          </div>
        </FilterSection>
        <FilterSection title={null} className="sliders">
          <_Slider
            value1={find_data.priceFrom}
            value2={find_data.priceTo}
            min={0}
            max={60000}
            handleChange={handleSliderData}
            label="price"
            type={2}
            title={t("advancedSearch:vehicle-price")}
            placeholder1={t("common:any")}
            placeholder2={t("common:any")}
          />
          <_Slider
            value1={find_data.yearFrom}
            value2={find_data.yearTo}
            min={1940}
            max={new Date().getFullYear()}
            handleChange={handleSliderData}
            label="year"
            type={2}
            title={t("common:first-registration")}
            placeholder1={t("common:any")}
            placeholder2={t("common:any")}
          />
          <_Slider
            value1={find_data.mileageFrom}
            value2={find_data.mileageTo}
            min={0}
            max={20000}
            handleChange={handleSliderData}
            label="mileage"
            type={2}
            title={t("common:mileage")}
            placeholder1={t("common:any")}
            placeholder2={t("common:any")}
          />
          <_Slider
            value1={find_data.powerFrom}
            value2={find_data.powerTo}
            min={0}
            max={2000}
            handleChange={handleSliderData}
            label="power"
            type={2}
            title={t("common:power")}
            placeholder1={t("common:any")}
            placeholder2={t("common:any")}
          >
            <label className="switch-power">
              <input type="checkbox" />
              <span className="switch-power-slider"></span>
            </label>
          </_Slider>
        </FilterSection>
        <FilterSection
          title={t("common:transmission")}
          className="fueltype-filter"
        >
          {sortedTransmissionByIds.map((item, index) => {
            if (item.name) {
              return (
                <CheckboxField
                  className={"fuel-check"}
                  checked={
                    find_data?.idtransmission === item.idtransmission
                      ? true
                      : false
                  }
                  changeHandler={() =>
                    checkboxChangeHandler(
                      "transmission",
                      item.idtransmission,
                      "idtransmission",
                      "idtransmission"
                    )
                  }
                  label={item.name}
                  key={index}
                />
              );
            } else {
              return null;
            }
          })}
        </FilterSection>
        <FilterSection title={t("common:fuel")} className="fueltype-filter">
          {fuel_type.map((item, index) => {
            if (item.name.EN) {
              return (
                <CheckboxField
                  className={"fuel-check"}
                  checked={find_data?.idFuelType === item.idfueltype}
                  changeHandler={() =>
                    checkboxChangeHandler(
                      "fuel_type",
                      item.idfueltype,
                      "idfueltype",
                      "idFuelType"
                    )
                  }
                  label={item.name[language ? language.toUpperCase() : "EN"]}
                  key={index}
                />
              );
            } else {
              return null;
            }
          })}
        </FilterSection>
        <Accordion
          title={t("common:vehicle-details")}
          className="filter-flex-wrap mainvehicledata"
        >
          {/* <FormControl
            inputType="select"
            label={ t('common:motor') }
            type="number"
            placeholder={ t('advancedSearch:any') }
            disabled={ motors.length === 0 }
            data={ motors }
            value={ motors.find((item) => item.idmotor === find_data?.idMotor) }
            handleChange={ (event, newValue) => setFindDataHandler('idMotor', newValue ? newValue.idmotor : '') }
          /> */}
          {/* <FormControl
            inputType="select"
            label={ t('common:door-count') }
            type="number"
            placeholder={ t('advancedSearch:any') }
            disabled={ doors.length === 0 }
            data={ doors }
            value={ doors.find((item) => item.iddoors === find_data?.idDoors) }
            handleChange={ (event, newValue) => setFindDataHandler('idDoors', newValue ? newValue.iddoors : '') }
          />
          <FormControl
            inputType="select"
            label={ t('common:number-of-seats') }
            type="number"
            placeholder={ t('advancedSearch:any') }
            disabled={ true }
            data={ seats }
            value={ seats.find((item) => item.idseats === find_data?.idSeats) }
            handleChange={ (event, newValue) => setFindDataHandler('idSeats', newValue ? newValue.idSeats : '') }

          /> */}
          <FormControl label={t("common:body-color")}>
            <ColorPicker
              id={"idbodycolor"}
              colors={bodyColors}
              label="bodyColor"
              value={find_data.idbodycolor ?? ""}
              changeHandler={(event, newValue) =>
                setFindDataHandler(
                  "idbodycolor",
                  newValue?.idbodycolor ? newValue.idbodycolor : null,
                  "array"
                )
              }
            />
          </FormControl>
          <FormControl
            inputType="checkbox"
            label={t("common:metallic")}
            handleChange={() =>
              checkboxHandler("metallicColor", !metallicColor)
            }
            value={metallicColor}
          />
          {/* <FormControl
            label={ t('common:alloy-wheels') }
            inputType="checkbox"
            disabled={ true }
          /> */}
          <FormControl label={t("common:interior-color")}>
            <ColorPicker
              id={"idinteriorcolor"}
              label="interiorColor"
              colors={interiorcolor}
              value={find_data.idinteriorcolor ?? ""}
              changeHandler={(event, newValue) =>
                setFindDataHandler(
                  "idinteriorcolor",
                  newValue?.idinteriorcolor ? newValue.idinteriorcolor : null,
                  "array"
                )
              }
            />
          </FormControl>
          <FormControl
            inputType="select"
            label={t("common:upholstery")}
            data={upholstery}
            value={upholstery.find(
              (item) => item.idupholstery === find_data.idupholstery
            )}
            handleChange={(event, newValue) =>
              setFindDataHandler("idupholstery", newValue?.idupholstery ?? "")
            }
          />
        </Accordion>
        <Accordion
          title={t("advancedSearch:condition-warranty")}
          className="filter-flex-wrap"
        >
          <FormControl
            inputType="select"
            label={t("common:condition")}
            data={condition}
            value={condition.find(
              (item) => item.idcondition === find_data.idcondition
            )}
            handleChange={(event, newValue) =>
              setFindDataHandler("idcondition", newValue?.idcondition ?? "")
            }
          />
          <FormControl
            inputType="select"
            label={t("common:previous-owners")}
            data={previousOwnerCount}
            value={previousOwnerCount.find(
              (item) => item.value === find_data.previousOwnerCount
            )}
            handleChange={(event, newValue) =>
              setFindDataHandler("previousOwnerCount", newValue?.value ?? "")
            }
          />
          <FormControl
            inputType="checkbox"
            label={t("common:damaged-vehicle")}
            handleChange={() =>
              checkboxHandler("damagedVehicle", !damagedVehicle)
            }
            value={damagedVehicle}
          />
          <FormControl
            inputType="checkbox"
            label={t("common:non-smoking-vehicle")}
            handleChange={() =>
              checkboxHandler("nonSmokingVehicle", !nonSmokingVehicle)
            }
            value={nonSmokingVehicle}
          />
          <FormControl
            inputType="checkbox"
            label={t("common:full-service-history")}
            handleChange={() =>
              checkboxHandler("fullServiceHistory", !fullServiceHistory)
            }
            value={fullServiceHistory}
          />
          <FormControl
            inputType="checkbox"
            label={t("common:guarantee")}
            handleChange={() => checkboxHandler("guarantee", !guarantee)}
            value={guarantee}
          />
          <FormControl
            inputType="checkbox"
            label={t("common:ha/au-new")}
            handleChange={() => checkboxHandler("huau", !huau)}
            value={huau}
          />
        </Accordion>
        <Accordion
          title={t("advancedSearch:ecological-criteria")}
          className="filter-flex-wrap"
        >
          <FormControl
            inputType="select"
            label={t("common:emission-class")}
            data={emissionClass}
            value={emissionClass.find(
              (item) => item.name === find_data?.emissionClass
            )}
            handleChange={(event, newValue) =>
              setFindDataHandler("emissionClass", newValue ? newValue.name : "")
            }
          />

          <FormControl
            inputType="select"
            label={t("common:emission-sticker")}
            data={emissionSticker}
            value={emissionSticker.find(
              (item) => item.name === find_data?.emissionSticker
            )}
            handleChange={(event, newValue) =>
              setFindDataHandler(
                "emissionSticker",
                newValue ? newValue.name : ""
              )
            }
          />
          <FormControl
            inputType="checkbox"
            label={t("common:particulate-filter")}
            handleChange={() =>
              checkboxHandler("particulateFilter", !particulateFilter)
            }
            value={particulateFilter}
          />
          <FormControl
            inputType="checkbox"
            label={t("common:catalytic-converter")}
            handleChange={() =>
              checkboxHandler("catalyticConverter", !catalyticConverter)
            }
            value={catalyticConverter}
          />
        </Accordion>
        <Accordion
          title={t("common:other-details")}
          className="filter-flex-wrap"
        >
          <FormControl
            inputType="checkbox"
            label={t("common:indicate-vat")}
            handleChange={() => checkboxHandler("indicateVat", !indicateVat)}
            value={indicateVat}
          />
          <FormControl
            inputType="datepicker"
            label={t("common:ad-online-since")}
            value={find_data?.adOnlineSince ? find_data.adOnlineSince : null}
            handleChange={(date) => handleDateChange(date._d)}
          />
          <FormControl
            inputType="checkbox"
            label={t("common:only-ads-with-pictures")}
            handleChange={() =>
              checkboxHandler("adsWithPictures", !adsWithPictures)
            }
            value={adsWithPictures}
          />
        </Accordion>
        <Button
          label={t("common:search")}
          className="button"
          click={handleSearch}
          color="red"
        />
      </main>
    </div>
  );
};

const stateToProps = (state) => {
  const {
    makeWithAll,
    carModelWithAll,
    find_data,
    fuel_type,
    bodyTypeWithAll,
    interiorcolor,
    condition,
    motors,
    upholstery,
    doors,
    seats,
    transmission,
    bodyTypeCount,
    bodyColors,
    idlocale,
  } = state.car;
  return {
    makeWithAll,
    carModelWithAll,
    find_data,
    fuel_type,
    bodyTypeWithAll,
    interiorcolor,
    condition,
    motors,
    upholstery,
    doors,
    seats,
    transmission,
    bodyTypeCount,
    bodyColors,
    idlocale,
  };
};

export default connect(stateToProps)(AdvancedSearch);
