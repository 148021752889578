import React, { useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Textfield from "../../components/text-field";
import { useTranslation } from "react-i18next";

import "./index.css";

const datePicker = (props) => {
  const { value, changeHandler } = props;
  const { t, i18n } = useTranslation(["common"]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        onChange={changeHandler}
        value={value}
        inputVariant="outlined"
        disableToolbar={true}
        TextFieldComponent={(inputProps, event) => {
          return (
            <Textfield
              clickHandler={inputProps.onClick}
              value={inputProps.value}
              placeholder={t("common:select")}
            />
          );
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default datePicker;
