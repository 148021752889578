import React, { useEffect } from "react";
import { connect } from "react-redux";
import { resetFindProps } from "../../store/actions";

const ClearState = ({ dispatch, pathname }) => {
  useEffect(() => {
    if (
      pathname === "/advanced-search" ||
      pathname === "/results" ||
      pathname.includes("/ad/")
    ) {
      return undefined;
    } else {
      dispatch(resetFindProps());
    }
  }, [pathname]);
};

export default connect()(ClearState);
