// car index
export const SETAUTHORIZATION = "SETAUTHORIZATION";
export const HIDENOTEMESSAGE = "HIDENOTEMESSAGE";
export const SET_PROTECTED_REFERRER = "SET_PROTECTED_REFERRER";
export const SET_SYS_LOCALE = "SET_SYS_LOCALE";
export const VALIDATE_EMAIL = "VALIDATE_EMAIL";
export const SEND_REFER = "SEND_REFER";
export const SEND_VOUCHER_CODE = "SEND_VOUCHER_CODE";
export const GET_VOUCHERS = "GET_VOUCHERS";
export const SET_VOUCHERS = "SET_VOUCHERS";
export const LOGOUT = "LOGOUT";
export const SETPROFILEDATA = "SETPROFILEDATA";
export const SETMERCHANTCARDDATA = "SETMERCHANTCARDDATA";

// socket
export const CONNECT = "CONNECT";
export const CONNECT_RESULT = "CONNECT_RESULT";
export const CONNECT_ERROR = "CONNECT_ERROR";

export const DISCONNECT = "DISCONNECT";
export const DISCONNECT_RESULT = "DISCONNECT_RESULT";
export const DISCONNECT_ERROR = "DISCONNECT_ERROR";

export const REGISTERWEBUSER = "REGISTERWEBUSER";
export const REGISTERWEBUSER_RESULT = "REGISTERWEBUSER_RESULT";
export const REGISTERWEBUSER_ERROR = "REGISTERWEBUSER_ERROR";

export const REGISTERSELLER = "REGISTERSELLER";
export const REGISTERSELLER_RESULT = "REGISTERSELLER_RESULT";
export const REGISTERSELLER_ERROR = "REGISTERSELLER_ERROR";

export const EDITPROFILEDATA = "EDITPROFILEDATA";
export const EDITPROFILEDATA_RESULT = "EDITPROFILEDATA_RESULT";
export const EDITPROFILEDATA_ERROR = "EDITPROFILEDATA_ERROR";

export const GETPROFILEDATA = "GETPROFILEDATA";
export const GETPROFILEDATA_RESULT = "GETPROFILEDATA_RESULT";
export const GETPROFILEDATA_ERROR = "GETPROFILEDATA_ERROR";

export const SETUSERLOCATION = "SETUSERLOCATION";
export const SETUSERLOCATION_RESULT = "SETUSERLOCATION_RESULT";
export const SETUSERLOCATION_ERROR = "SETUSERLOCATION_ERROR";

export const ADDMERCHANTCARD = "ADDMERCHANTCARD";
export const ADDMERCHANTCARD_RESULT = "ADDMERCHANTCARD_RESULT";
export const ADDMERCHANTCARD_ERROR = "ADDMERCHANTCARD_ERROR";

export const GETMERCHANTCARD = "GETMERCHANTCARD";
export const GETMERCHANTCARD_RESULT = "GETMERCHANTCARD_RESULT";
export const GETMERCHANTCARD_ERROR = "GETMERCHANTCARD_ERROR";

export const REGISTERUSER = "REGISTERUSER";
export const REGISTERUSER_RESULT = "REGISTERUSER_RESULT";
export const REGISTERUSER_ERROR = "REGISTERUSER_ERROR";

export const CONFIRMTOKEN = "CONFIRMTOKEN";
export const CONFIRMTOKEN_RESULT = "CONFIRMTOKEN_RESULT";
export const CONFIRMTOKEN_ERROR = "CONFIRMTOKEN_ERROR";

export const RECOVERPASSWORD = "RECOVERPASSWORD";
export const RECOVERPASSWORD_RESULT = "RECOVERPASSWORD_RESULT";
export const RECOVERPASSWORD_ERROR = "RECOVERPASSWORD_ERROR";

export const CHANGEPASSWORD = "CHANGEPASSWORD";
export const CHANGEPASSWORD_RESULT = "CHANGEPASSWORD_RESULT";
export const CHANGEPASSWORD_ERROR = "CHANGEPASSWORD_ERROR";

export const CLEAR_DATA = "CLEAR_DATA";

export const RESETCONNECTFAIL = "RESETCONNECTFAIL";

export const SET_CHOOSE_TYPE = "SET_CHOOSE_TYPE";
