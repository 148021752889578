export const transmission = [
  { label: "Manual", checked: false },
  { label: "Automatic", checked: false },
  { label: "Semi-automatic", checked: false },
];

export const equipment = [
  { label: "360 camera", checked: false },
  { label: "4WD", checked: false },
  { label: "ABS", checked: false },
  { label: "Adaptive Cruise Control", checked: false },
  { label: "Adaptive headlights", checked: false },
  { label: "Air conditioning", checked: false },
  { label: "Air suspension", checked: false },
  { label: "Alarm system", checked: false },
  { label: "Alloy wheels", checked: false },
  { label: "Ambient lightning", checked: false },
];

export const emissionClass = [
  { name: "Euro 1" },
  { name: "Euro 2" },
  { name: "Euro 3" },
  { name: "Euro 4" },
  { name: "Euro 5" },
  { name: "Euro 6" },
  { name: "Euro 6c" },
  { name: "Euro 6d" },
  { name: "Euro 6c-TEMP" },
];

export const emissionSticker = [
  { name: "min. 2 (Red)" },
  { name: "min. 3 (Yellow)" },
  { name: "min. 4 (Green)" },
  { name: "min. 5 (Blue)" },
];

export const perimetarOptions = [
  { name: "All", value: null, radius: null },
  { name: "10 km", value: 10000, radius: 100 },
  { name: "20 km", value: 20000, radius: 200 },
  { name: "30 km", value: 30000, radius: 300 },
  { name: "40 km", value: 40000, radius: 400 },
  { name: "50 km", value: 50000, radius: 500 },
  { name: "75 km", value: 75000, radius: 750 },
  { name: "100 km", value: 100000, radius: 1000 },
  { name: "200 km", value: 200000, radius: 2000 },
  { name: "300 km", value: 300000, radius: 3000 },
];
