import React from "react";
import { useHistory } from "react-router";
import Image from "../../../components/image";
import { PhotoPlaceholder } from "../../../components/svg-icons/index.js";
import pagel from "../../../assets/images/seller-logos/pagel.png";
import driverama from "../../../assets/images/seller-logos/driverama.png";
import stern from "../../../assets/images/seller-logos/sternpartner.png";
import privateSeller from "../../../assets/images/seller-logos/privateseller.png";
import Button from "../../../components/button";
import { numberWithCommas } from "../../../lib/utils.js";

import "./index.css";

const CarsOfDay = ({ cars }) => {
  const history = useHistory();
  const sellerLogos = [
    { seller: pagel },
    { seller: driverama },
    { seller: stern },
    { seller: privateSeller },
  ];

  /**
   *-----------------------------------------------
   * GO TO AD DETAILS
   *-----------------------------------------------
   * @param { Number } idad
   */
  const previewAdHandler = (idad) => {
    history.push({
      pathname: `/ad/${idad}`,
    });
  };

  return (
    <div className="cars-of-day">
      {cars.map((item, index) => {
        const { idad, price, photos } = item || {};
        if (!idad || cars.findIndex((car) => car.idad === idad) !== index) {
          return null;
        }
        const seller = sellerLogos[index] ?? null;
        return (
          <div className="ad-thumb" key={idad}>
            <span className="seller-logo">
              {seller && <img src={seller.seller} />}
            </span>
            <span className="ad-img">
              <section onClick={() => previewAdHandler(idad)}>
                <div>
                  {photos ? (
                    <Image src={photos[0]} className="cover" />
                  ) : (
                    <PhotoPlaceholder />
                  )}
                </div>
                <Button
                  label={`CHF ${numberWithCommas(price)}.-`}
                  color="red"
                  click={() => previewAdHandler(idad)}
                />
              </section>
            </span>
          </div>
        );
      })}
    </div>
  );
};
export default CarsOfDay;
