import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ArrowDown } from "../../components/svg-icons";
import { useHistory } from "react-router";
import { setFindProps, findAds2, resetAds } from "../../store/actions";
import { getFavoritesIds, getPropName, toggleFavorites } from "../../lib/utils";
import { Save } from "../../components/dialog";
import Aside from "../../components/aside";
import Ads from "../../components/ads";
import "./index.css";
import SimpleLoader from "../../components/simple-loader";

const Result = (props) => {
  const {
    dispatch,
    ads,
    find_data,
    ads_found,
    loading,
    make,
    car_model,
    fuel_type,
    body_type,
    ads_total,
  } = props;
  const adsPerPage = 5;
  const { t } = useTranslation(["results, common"]);
  const [openModal, setOpenModal] = useState(false);
  const [savedSearchName, setSavedSearchName] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortLabel, setSortLabel] = useState("common:recommended");
  const [activePage, setActivePage] = useState(1);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [favoriteIds, setFavoriteIds] = useState([]);
  const [readyToSaveSearch, setReadyToSaveSearch] = useState(false);
  const [showSaveSearchBtn, setShowSaveSearchBtn] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  let totalPages = parseInt(ads_found / adsPerPage, 10);
  if (ads_found % adsPerPage > 0) {
    totalPages++;
  }

  /**
   *-----------------------------------------------
   * HIDE SAVE SEARCH DIALOG BOX
   *-----------------------------------------------
   */
  const hideDialog = () => {
    setOpenModal(false);
    setErrorMessage(null);
    setSavedSearchName("");
  };

  /**
   *-----------------------------------------------
   * SORT LOGIC
   *-----------------------------------------------
   */
  const handleSort = (idSort) => {
    switch (idSort) {
      case 1:
        setSortLabel("common:price-lowest");
        break;
      case 2:
        setSortLabel("common:price-highest");
        break;
      case 3:
        setSortLabel("common:mileage-lowest");
        break;
      case 4:
        setSortLabel("common:mileage-highest");
        break;
      case 5:
        setSortLabel("common:first-registration-lowest");
        break;
      case 6:
        setSortLabel("common:first-registration-highest");
        break;
      case 7:
        setSortLabel("common:make-mode-asc");
        break;
      case 8:
        setSortLabel("common:make-mode-desc");
        break;
      default:
        setSortLabel("common:recommended");
        break;
    }
    dispatch(setFindProps("fromCount", 0));
    dispatch(setFindProps("sort", idSort));
    dispatch(findAds2());
    setActivePage(1);
    setAnchorEl(null);
  };

  /**
   *-----------------------------------------------
   * GET DATA BY FILTER PARAM ID --need to be ref.
   *-----------------------------------------------
   */
  const getFilterNames = () => {
    const findData = { ...find_data };
    delete findData.fromCount;
    delete findData.limitCount;
    delete findData.sort;
    delete findData.idModel;
    const filterNames = Object.keys(findData).map((item) => {
      switch (item) {
        case "idMake": {
          const vehiclebrand = find_data[item].map((item, index) => {
            return `+${getPropName(make, find_data.idMake[index], "idmake")} ${
              find_data.idModel !== null
                ? getPropName(car_model, find_data.idModel[index], "idmodel")
                : ""
            }`;
          });
          return vehiclebrand.join(",");
        }
        case "yearFrom": {
          return `+year from ${find_data.yearFrom}`;
        }
        case "yearTo": {
          return `+year to ${find_data.yearTo}`;
        }
        case "mileageFrom": {
          return `+mileage from ${find_data.mileageFrom}`;
        }
        case "mileageTo": {
          return `+mileage to ${find_data.mileageTo}`;
        }
        case "priceFrom": {
          return `+price from ${find_data.priceFrom}`;
        }
        case "priceTo": {
          return `+price to ${find_data.priceTo}`;
        }
        case "idFuelType": {
          return `+fuel ${getPropName(
            fuel_type,
            find_data.idFuelType,
            "idfueltype"
          )}`;
        }
        case "idBodyType": {
          return `+body ${getPropName(
            body_type,
            find_data.idBodyType,
            "idbodytype"
          )}`;
        }
        case "bodyColor": {
          return `+body color ${find_data[item].map(
            (item) => `${item} ${find_data.bodyColor.length > 1 ? "," : ""}`
          )}`;
        }
        case "metallicColor": {
          return "+metllic";
        }
        case "interiorColor": {
          return `+interior color ${find_data.interiorColor}`;
        }
        case "upholstery": {
          return `+upholstery ${find_data.upholstery}`;
        }
        case "condition": {
          return `+condition ${find_data.condition}`;
        }
        case "previousOwnerCount": {
          return `+previous owners ${find_data.previousOwnerCount}`;
        }
        case "damagedVehicle": {
          return `+damaged vehicle`;
        }
        case "nonSmokingVehicle": {
          return `+non smoking car`;
        }
        case "fullServiceHistory": {
          return `+full service`;
        }
        case "huauUntil": {
          return `+hu/au until ${find_data.huauUntil}`;
        }
        case "emissionClass": {
          return `+emission class ${find_data.emissionClass}`;
        }
        case "emissionSticker": {
          return `+emission sticker ${find_data.emissionSticker}`;
        }
        case "indicateVat": {
          return `+indicate vat`;
        }
        case "adOnlineSince": {
          return `+online since ${find_data.adOnlineSince}`;
        }
        case "adsWithPictures": {
          return `+with pictures`;
        }
      }
    });
    return filterNames;
  };

  /**
   *-----------------------------------------------
   * SAVE CURRENT SEARCH LOGIC --need to be ref.
   *-----------------------------------------------
   */
  const saveSearchHandler = () => {
    let savedSearches = JSON.parse(localStorage.getItem("searches"));

    const searchData = {
      date: new Date().toISOString().split("T")[0],
      name: savedSearchName,
      adsFound: ads_found,
      filtersApplied: { ...find_data },
      filterNames: getFilterNames(),
    };
    if (savedSearches === null) {
      savedSearches = [searchData];
    } else {
      savedSearches = [...savedSearches, searchData];
    }

    localStorage.setItem("searches", JSON.stringify(savedSearches));
    hideDialog();
    setErrorMessage(null);
    setShowSaveSearchBtn(false);
    setReadyToSaveSearch(false);
  };

  const setSaveSearchRequirements = () => {
    dispatch(findAds2());
    setReadyToSaveSearch(true);
  };

  const isDisabled = () => savedSearchName === "";

  useEffect(() => {
    if (readyToSaveSearch) {
      saveSearchHandler();
    }
  }, [ads]);

  /**
   *-----------------------------------------------
   * PAGINATION LOGIC
   *-----------------------------------------------
   * @param { Number } currentPage
   */
  const paginationHandler = (currentPage) => {
    dispatch(setFindProps("fromCount", adsPerPage * currentPage - adsPerPage));
    setActivePage(currentPage);
  };

  /**
   *-----------------------------------------------
   * GO TO ADS PAGE AND PREVIEW AD DETAILS
   *-----------------------------------------------
   * @param { Number } idad
   */
  const previewAdHandler = (idad) => {
    localStorage.setItem("previewAd", true);
    history.push({
      pathname: `/ad/${idad}`,
      state: { activePage: activePage, sortLabel: sortLabel },
    });
  };

  const goToAdvancedSearch = () => {
    history.push("advanced-search");
  };

  /**
   *-----------------------------------------------
   * TOGGLE FAVORITE ADS
   *-----------------------------------------------
   * @param { Number } idad
   */
  const toggleFavoritesHandler = (idad) => {
    toggleFavorites(idad);
    const favorites = getFavoritesIds();
    setFavoriteIds(favorites);
  };

  /**
   *-----------------------------------------------
   * GET FAVORITE IDS
   *-----------------------------------------------
   * @param { Number } idad
   */
  useEffect(() => {
    const favorites = getFavoritesIds();
    setFavoriteIds(favorites);
  }, []);

  /**
   *-----------------------------------------------
   * SET ACTIVE PAGE
   *-----------------------------------------------
   */
  useEffect(() => {
    const previewAd = localStorage.getItem("previewAd");
    if (previewAd) {
      const activePage = parseInt(localStorage.getItem("activePage"));
      const sortLabel = localStorage.getItem("sortLabel");
      setActivePage(activePage);
      setSortLabel(sortLabel);
      localStorage.removeItem("activePage");
      localStorage.removeItem("previewAd");
      localStorage.removeItem("sortLabel");
    } else {
      setActivePage(1);
    }
  }, []);

  /**
   *-----------------------------------------------
   * FETCH ADS ON PAGE CHANGE
   *-----------------------------------------------
   */
  useEffect(() => {
    dispatch(findAds2());
  }, [activePage]);

  /**
   *-----------------------------------------------
   * CLEAR ADS
   *-----------------------------------------------
   */
  useEffect(() => {
    return () => {
      dispatch(resetAds());
    };
  }, []);

  useEffect(() => {
    setShowSaveSearchBtn(true);
  }, [find_data]);

  const title = (
    <p>
      {t("results:yoursearchcriteria")} <span>{ads_found}</span>{" "}
      {t("results:cars")}
    </p>
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box className="results-page">
      {screenWidth <= 480 ? (
        <Accordion>
          <AccordionSummary
            expandIcon={ArrowDown}
            aria-controls="headline"
            id="headline"
          >
            <h4 className="subtitle">{t("common:filters")}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <Aside
              openDialogBoxHandler={() => setOpenModal(true)}
              setActivePage={setActivePage}
              showSaveSearchBtn={showSaveSearchBtn}
              goToAdvancedSearch={goToAdvancedSearch}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <Aside
          openDialogBoxHandler={() => setOpenModal(true)}
          setActivePage={setActivePage}
          showSaveSearchBtn={showSaveSearchBtn}
          goToAdvancedSearch={goToAdvancedSearch}
        />
      )}
      <Box className="results-page-main">
        <Ads
          data={ads}
          loading={loading}
          activePage={activePage}
          totalPages={totalPages}
          paginationHandler={paginationHandler}
          sortLabel={sortLabel}
          openSort={(event) => setAnchorEl(event.currentTarget)}
          anchorEl={anchorEl}
          handleSort={handleSort}
          dropdownData={[
            "common:recommended",
            "common:price-lowest",
            "common:price-highest",
            "common:mileage-lowest",
            "common:mileage-highest",
            "common:first-registration-lowest",
            "common:first-registration-highest",
            "common:make-mode-asc",
            "common:make-mode-desc",
          ]}
          previewAd={previewAdHandler}
          toggleFavorites={toggleFavoritesHandler}
          favoriteIds={favoriteIds}
          ads_total={ads_total}
          path="results"
        >
          {title}
        </Ads>
      </Box>
      <SimpleLoader loading={loading && openModal}>
        <Save
          openModal={openModal}
          hideDialog={hideDialog}
          action={setSaveSearchRequirements}
          title={t("common:save")}
          info={t("results:to-save-the-current-search")}
          changeHandler={(e) => setSavedSearchName(e.target.value)}
          errorMessage={errorMessage}
          value={savedSearchName}
          disabled={isDisabled()}
        />
      </SimpleLoader>
    </Box>
  );
};

const stateToProps = (state) => {
  const {
    ads,
    find_data,
    loading,
    ads_found,
    make,
    car_model,
    fuel_type,
    body_type,
    ads_total,
  } = state.car;
  return {
    ads,
    find_data,
    loading,
    ads_found,
    make,
    car_model,
    fuel_type,
    body_type,
    ads_total,
  };
};

export default connect(stateToProps)(Result);
