import Home from "./screens/home";
import Results from "./screens/results";
import Favourites from "./screens/favourites";
import MyCars from "./screens/mycars";
import SavedSearch from "./screens/saved-search";
import Details from "./screens/details";
import Sell from "./screens/sell/index";
import AdvancedSearch from "./screens/advanced-search/";
import UserProfile from "./screens/profile/";
import SingUp from "./screens/auth/signup";
import SignIn from "./screens/auth/signin";
import VerifyEmail from "./screens/auth/verify-email";
import RecoverPassword from "./screens/auth/recover-password";
import ChangePassword from "./screens/auth/change-password";
import ReportAd from "./screens/report-ad/";
import TermsAndConditions from "./screens/footer-links/terms-conditions";
import InsertionRules from "./screens/footer-links/insertion-rules";
import Impressum from "./screens/footer-links/impressum";
import DataProtection from "./screens/footer-links/data-protection";
import SafetyInstructions from "./screens/footer-links/safety-instructions";

const navConfig = {
  routes: [
    { path: "/home", component: Home, exact: true, defautl: true },
    {
      path: "/favorites",
      component: Favourites,
      exact: true,
      protected: true,
      userTypes: [1, 2],
    },
    { path: "/my-cars", component: MyCars, protected: true, userTypes: [2] },
    {
      path: "/user-profile",
      component: UserProfile,
      protected: true,
      userTypes: [1, 2],
    },
    {
      path: "/saved-searches",
      component: SavedSearch,
      exact: false,
      protected: true,
      userTypes: [1, 2],
    },
    { path: "/sell", component: Sell, protected: true, userTypes: [2] },
  ],
  authRoutes: [
    { label: "Logout", path: "/signout", icon: "logout", fn: "logout" },
    { label: "SingUp", path: "/signup", component: SingUp, protected: false },
    { label: "SignIn", path: "/signin", component: SignIn, protected: false },
    {
      label: "VerifyEmail",
      path: "/verify-email/:hash",
      component: VerifyEmail,
      protected: false,
    },
    {
      label: "RecoverPassword",
      path: "/recover-password",
      component: RecoverPassword,
      protected: false,
    },
    {
      label: "ChangePassword",
      path: "/change-password/:hash",
      component: ChangePassword,
      protected: false,
    },
  ],
  additionalRoutes: [],
  legalRoutes: [
    { path: "/legal/terms-and-conditions", component: TermsAndConditions },
    { path: "/legal/insertion-rules", component: InsertionRules },
    { path: "/legal/impressum", component: Impressum },
    { path: "/legal/data-protection", component: DataProtection },
    { path: "/legal/safety-instructions", component: SafetyInstructions },
  ],

  notInMenuRoutes: [
    {
      path: "/ad/:idad/edit",
      component: Sell,
      protected: true,
      userTypes: [2],
    },
    {
      path: "/ad/:idad/report",
      component: ReportAd,
      protected: true,
      userTypes: [1],
    },
    { path: "/ad/:idad", exact: true, component: Details },
    { path: "/ad", exact: true, component: Details },
    { path: "/advanced-search", component: AdvancedSearch },

    { path: "/results", component: Results },
  ],
};

export default navConfig;
