import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  findAds2,
  resetFindProps,
  setFindProps,
  customSetFindProps,
  getCarModel,
  removeCarModel,
  resetCarModel,
} from "../../store/actions";
import { setSearchProps, countFilters } from "../../lib/utils";
import { ArrowRight, ArrowUpRed } from "../svg-icons";
import { yearsWithAll } from "../../data";
import Select from "../select/index";
import TextField from "../text-field/index";
import Button from "../../components/button/index";
import FormControl from "../form-control";
import "./index.css";

const aside = (props) => {
  const {
    dispatch,
    makeWithAll,
    carModelWithAll,
    find_data,
    setActivePage,
    showSaveSearchBtn,
    fuelTypeWithAll,
    bodyTypeWithAll,
    transmissionWithAll,
    goToAdvancedSearch,
  } = props;
  const { t } = useTranslation(["common, results"]);
  const [selectedFilterCount, setSelectedFilterCount] = useState(0);
  const transformed_bodytype = bodyTypeWithAll.filter((item) => item.name.EN);
  const transformed_fueltype = fuelTypeWithAll.filter((item) => item.name.EN);

  const filterKeys = [
    "idMake",
    "idModel",
    "idBodyType",
    "priceFrom",
    "priceTo",
    "yearFrom",
    "yearTo",
    "mileageFrom",
    "mileageTo",
    "idtransmission",
    "idFuelType",
    "powerFrom",
    "powerTo",
  ];

  useEffect(() => {
    const initialCount = countFilters(find_data, filterKeys);
    setSelectedFilterCount(initialCount);
  }, [find_data]);

  useEffect(() => {
    if (
      !(find_data?.idMake?.length > 0 && find_data.idMake[0] !== null) &&
      !(find_data?.idBodyType?.length > 0)
    ) {
      dispatch(getCarModel(0, 0));
      dispatch(customSetFindProps("idMake", [null]));
      dispatch(customSetFindProps("idModel", [null]));
      dispatch(customSetFindProps("idBodyType", []));
    } else if (
      Array.isArray(find_data.idMake) &&
      !Array.isArray(find_data.idModel)
    ) {
      dispatch(customSetFindProps("idModel", [null]));
    }
  }, []);

  const setSearchPropsHandler = (key, value) => {
    setSearchProps(dispatch, key, value);
  };

  const handleClearFilters = () => {
    dispatch(setFindProps("fromCount", 0));
    dispatch(resetFindProps());
    dispatch(getCarModel(0, 0));
    dispatch(customSetFindProps("idMake", [null]));
    dispatch(customSetFindProps("idModel", [null]));
    dispatch(findAds2());
    setActivePage(1);
  };

  const findHandler = () => {
    dispatch(setFindProps("fromCount", 0));
    dispatch(findAds2());
    setActivePage(1);
  };

  const addMakeOrModel = (key, value, index) => {
    value = value === -1 ? null : value;
    if (key === "idMake") {
      if (value !== null) {
        dispatch(getCarModel(value, index));
      }
      dispatch(resetCarModel(index));
      const newMake = [...find_data.idMake];
      newMake[index] = value;
      const newModel = [...find_data.idModel];
      newModel[index] = null;
      const newBodyType = [...find_data.idBodyType];
      newBodyType[index] = null;
      dispatch(customSetFindProps("idMake", newMake));
      dispatch(customSetFindProps("idModel", newModel));
      dispatch(customSetFindProps("idBodyType", newBodyType));
    } else if (key === "idModel") {
      const newModel = [...find_data.idModel];
      newModel[index] = value;
      dispatch(customSetFindProps("idModel", newModel));
    } else if (key === "idBodyType") {
      let newBodyType = [...find_data.idBodyType];

      newBodyType[index] = value;
      dispatch(customSetFindProps("idBodyType", newBodyType));
    }
  };

  const addAnotherVehicle = () => {
    dispatch(customSetFindProps("idMake", [...find_data.idMake, null]));
    dispatch(customSetFindProps("idModel", [...find_data.idModel, null]));
    dispatch(customSetFindProps("idBodyType", [...find_data.idBodyType, null]));
  };

  const deleteVehicle = (indexPosition) => {
    dispatch(removeCarModel(indexPosition));
    const newMake = find_data.idMake.filter(
      (_, index) => index !== indexPosition
    );
    const newModel = find_data.idModel.filter(
      (_, index) => index !== indexPosition
    );
    const newBodyType = find_data.idBodyType
      ? find_data.idBodyType.filter((_, index) => index !== indexPosition)
      : [];
    dispatch(customSetFindProps("idMake", newMake));
    dispatch(customSetFindProps("idModel", newModel));
    dispatch(customSetFindProps("idBodyType", newBodyType));
  };

  return (
    <aside className="results-filters">
      <header>
        <div>
          <p>
            {t("common:filters")} <span>({selectedFilterCount})</span>
          </p>
          {ArrowUpRed}
        </div>
        <Button
          click={handleClearFilters}
          className="clear-all-button"
          label={t("common:clear-all")}
          color="transparent"
        />
      </header>
      <main>
        <Button
          click={props.openDialogBoxHandler}
          className="results-aside-btn"
          style={{ display: showSaveSearchBtn ? "block" : "none" }}
          label={t("results:savesearch")}
          color="white"
        />
        {find_data?.idMake?.length > 0 &&
          find_data?.idMake.map((_, index) => (
            <div key={index} className="mb-1">
              <FormControl
                inputType="select"
                label={t("common:make")}
                placeholder={t("common:all")}
                data={makeWithAll}
                value={makeWithAll.find(
                  (item) => item.idmake === find_data.idMake[index]
                )}
                handleChange={(_, newValue) =>
                  addMakeOrModel(
                    "idMake",
                    newValue ? newValue.idmake : null,
                    index
                  )
                }
              />
              <FormControl
                inputType="select"
                label={t("common:model")}
                data={
                  Array.isArray(carModelWithAll[index])
                    ? carModelWithAll[index]
                    : []
                }
                value={(Array.isArray(carModelWithAll[index])
                  ? carModelWithAll[index]
                  : []
                ).find(
                  (item) =>
                    item.idmodel ===
                    (Array.isArray(find_data.idModel) &&
                    find_data.idModel[index] !== undefined
                      ? find_data.idModel[index]
                      : null)
                )}
                handleChange={(_, newValue) =>
                  addMakeOrModel(
                    "idModel",
                    newValue ? newValue.idmodel : null,
                    index
                  )
                }
              />
              <FormControl
                inputType="select"
                label={t("common:body-type")}
                placeholder={t("common:all")}
                data={transformed_bodytype}
                value={
                  Array.isArray(find_data?.idBodyType) &&
                  find_data.idBodyType[index] !== undefined
                    ? transformed_bodytype.find(
                        (item) =>
                          item.idbodytype === find_data.idBodyType[index]
                      )
                    : null
                }
                handleChange={(_, newValue) =>
                  addMakeOrModel(
                    "idBodyType",
                    newValue ? newValue.idbodytype : null,
                    index
                  )
                }
              />
              {index + 1 === find_data?.idMake?.length ? (
                <div className="addOrDelete" onClick={addAnotherVehicle}>
                  {`+ ${t("results:add-make-model")}`}
                </div>
              ) : (
                <div
                  className="addOrDelete"
                  onClick={() => deleteVehicle(index)}
                >
                  {`- ${t("common:remove")}`}
                </div>
              )}
            </div>
          ))}
        <FormControl label={t("common:price")} className="filters-flex-row">
          <TextField
            placeholder={t("common:from")}
            type="number"
            value={find_data.priceFrom ? find_data.priceFrom : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("priceFrom", Number(event.target.value))
            }
          />
          <TextField
            placeholder={t("common:to")}
            type="number"
            value={find_data.priceTo ? find_data.priceTo : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("priceTo", Number(event.target.value))
            }
          />
        </FormControl>
        <FormControl
          label={t("common:first-registration")}
          className="filters-flex-row"
        >
          <Select
            placeholder={t("common:from")}
            label="yearFrom"
            data={yearsWithAll}
            handleChange={(_, newValue) =>
              setSearchPropsHandler("yearFrom", newValue ? newValue.year : "")
            }
            defaultValue={yearsWithAll.find(
              (item) => item.year === find_data.yearFrom
            )}
          />
          <Select
            placeholder={t("common:to")}
            label="yearTo"
            data={yearsWithAll}
            handleChange={(_, newValue) =>
              setSearchPropsHandler("yearTo", newValue ? newValue.year : "")
            }
            defaultValue={yearsWithAll.find(
              (item) => item.year === find_data.yearTo
            )}
          />
        </FormControl>
        <FormControl label={t("common:mileage")} className="filters-flex-row">
          <TextField
            placeholder={t("common:from")}
            type="number"
            value={find_data.mileageFrom ? find_data.mileageFrom : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("mileageFrom", Number(event.target.value))
            }
          />
          <TextField
            placeholder={t("common:to")}
            type="number"
            value={find_data.mileageTo ? find_data.mileageTo : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("mileageTo", Number(event.target.value))
            }
          />
        </FormControl>
        <FormControl label={t("common:power")} className="filters-flex-row">
          <TextField
            placeholder={t("common:from")}
            type="number"
            value={find_data.powerFrom ? find_data.powerFrom : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("powerFrom", Number(event.target.value))
            }
          />
          <TextField
            placeholder={t("common:to")}
            type="number"
            value={find_data.powerTo ? find_data.powerTo : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("powerTo", Number(event.target.value))
            }
          />
        </FormControl>
        <FormControl
          inputType="select"
          label={t("common:transmission")}
          placeholder={t("common:all")}
          data={transmissionWithAll}
          value={transmissionWithAll.find(
            (item) => item.idtransmission === find_data.idtransmission
          )}
          handleChange={(_, newValue) =>
            setSearchPropsHandler(
              "idtransmission",
              newValue ? newValue.idtransmission : ""
            )
          }
        />
        <FormControl
          inputType="select"
          label={t("common:fuel-type")}
          placeholder={t("common:all")}
          data={transformed_fueltype}
          value={transformed_fueltype.find(
            (item) => item.idfueltype === find_data.idFuelType
          )}
          handleChange={(_, newValue) =>
            setSearchPropsHandler(
              "idFuelType",
              newValue ? newValue.idfueltype : ""
            )
          }
        />
        <Button
          click={findHandler}
          className="results-aside-btn"
          label={t("results:findads")}
          color="white"
        />
        <Button
          color="transparent"
          className="results-aside-advanced-search"
          click={goToAdvancedSearch}
        >
          <span>{t("common:advanced-search")}</span>
          <ArrowRight />
        </Button>
      </main>
    </aside>
  );
};

const stateToProps = (state) => {
  const {
    makeWithAll,
    carModelWithAll,
    find_data,
    transmission,
    fuelTypeWithAll,
    bodyTypeWithAll,
    transmissionWithAll,
  } = state.car;
  return {
    makeWithAll,
    carModelWithAll,
    find_data,
    transmission,
    fuelTypeWithAll,
    bodyTypeWithAll,
    transmissionWithAll,
  };
};

export default connect(stateToProps)(aside);
