import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  getInteriorColorForLocale,
  getUpholsteryForLocale,
  getConditionForLocale,
  setGeneralData,
  setVehicleData,
  setEngineEnviroment,
  setFuelConsuption,
  setConditionMaintenance,
  setOptionalEquipmentIds,
  setAdDescription,
  setRootAdData,
  checkboxChange,
  getDoors,
  getSeats,
  getBodyColorForLocale,
  getDriveType,
} from "../../../store/actions";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ArrowDown } from "../../../components/svg-icons/index";
import {
  numericMonths,
  getNumericMonthsWithCancel,
  years,
  getYearsWithCancel,
  getEquipment,
  getTrailerHitchWithAll,
  getTrailerLoadWithAll,
  getEuroNormWithAll,
} from "../../../data";
import Select from "../../../components/select/index";
import FormControl from "../../../components/form-control/index";
import ColorPicker from "../../../components/color-picker";
import { TextField } from "@material-ui/core";
import NumberFormatCustom from "../../../components/numberFormatCustom/index";
import {
  doNotShowLetterE,
  getPropName,
  removePropsFromObject,
} from "../../../lib/utils";
import IMark from "../../../assets/shapes/i-mark.svg";
import "./index.css";

// Equipment-list checkbox values (load only once)
const equipmentList = getEquipment();

const VehicleDetails = (props) => {
  const {
    dispatch,
    make,
    car_model,
    fuelTypeWithAll,
    bodyTypeWithAll,
    doors,
    seats,
    transmission,
    gears,
    numberofcylinders,
    curbweight,
    condition,
    ad_data,
    vehicle_location,
    general_data,
    vehicle_data,
    condition_maintenance,
    optional_equipmentids,
    engine_environment,
    fuel_consumption,
    ad_description,
    motors,
    upholstery,
    bodyColors,
    idlocale,
    interiorcolor,
    errorDates,
    energyEfficienceData,
    driveTypeWithAll,
  } = props;

  const { t } = useTranslation(["common", "sell", "equipmentList"]);
  const makename = getPropName(make, ad_data?.idmake, "idmake");
  const modelname = getPropName(car_model, ad_data?.idmodel, "idmodel");
  const trailerHitch = getTrailerHitchWithAll();
  const trailerLoad = getTrailerLoadWithAll();
  const yearsWithCancel = getYearsWithCancel();
  const numericMonthsWithCancel = getNumericMonthsWithCancel();
  const euroNorm = getEuroNormWithAll();
  const [expandConditionalAndMaintenance, setExpandConditionalAndMaintenance] =
    useState(false);

  const equipmentLinePreview = useMemo(() => {
    if (general_data?.equipmentline) {
      const equipmentLineValue = motors?.filter((item) => {
        if (item.idmotor === Number(general_data?.equipmentline)) return item;
      });
      if (equipmentLineValue.length > 0) {
        return equipmentLineValue[0].name;
      }
      return general_data?.equipmentline;
    }
    return null;
  }, [general_data?.equipmentline, motors]);

  /**
   *-----------------------------------------------
   * SET GENERAL DATA
   *-----------------------------------------------
   * @param {String} argument
   * @param {String} value
   */
  const setGeneralDataHandler = (argument, value) => {
    if (value === "" && argument === "idmotor") {
      let updatedGeneralData = removePropsFromObject(general_data, [
        "idmotor",
        "power",
      ]);
      let updatedVehicleData = removePropsFromObject(vehicle_data, [
        "doorcount",
        "numberofseats",
      ]);
      let updatedEngineEnvironmentData = removePropsFromObject(
        engine_environment,
        ["numberofgears", "cylinder", "kerbweight"]
      );

      dispatch(setGeneralData(updatedGeneralData));
      dispatch(setVehicleData(updatedVehicleData));
      dispatch(setEngineEnviroment(updatedEngineEnvironmentData));
    } else {
      dispatch(setGeneralData({ ...general_data, [argument]: value }));
    }
  };

  /**
   *-----------------------------------------------
   * SET VEHICLE DATA
   *-----------------------------------------------
   * @param {String} argument
   * @param {String || Number} value
   */
  const setVehicleDataHandler = (argument, value) => {
    dispatch(setVehicleData({ ...vehicle_data, [argument]: value }));
  };

  /**
   *-----------------------------------------------
   * SET ENGINE_ENVIRONMENT DATA
   *-----------------------------------------------
   * @param {String} argument
   * @param {String || Number || Boolean} value
   */
  const setEngineEnvironmentHandler = (argument, value) => {
    dispatch(setEngineEnviroment({ ...engine_environment, [argument]: value }));
  };

  /**
   *-----------------------------------------------
   * SET FUEL_CONSUPTION DATA
   *-----------------------------------------------
   * @param {String} argument
   * @param {Number} value
   */
  const setFuelConsumptionDataHandler = (argument, value) => {
    dispatch(setFuelConsuption({ ...fuel_consumption, [argument]: value }));
  };

  /**
   *-----------------------------------------------
   * SET CONDITION_MAINTANANCE DATA
   *-----------------------------------------------
   * @param {String} argument
   * @param {String || Boolean} value
   */
  const setConditionMaintenanceDataHandler = (argument, value) => {
    let params = {};

    const setConditionalMaintenanceKeys = (field, fieldValue) => {
      params = { [field]: fieldValue };
    };

    switch (argument) {
      case "recenttechnicalservice":
      case "frommfk":
      case "guarantee":
      case "directimport":
      case "handicappedaccessible":
      case "damagedvehicle":
      case "nonsmokercar":
        setConditionalMaintenanceKeys(argument, value);
        break;

      case "monthlastTechnicalService":
      case "yearslastTechnicalService":
        const field =
          argument === "monthlastTechnicalService" ? "month" : "year";
        if (value[field] !== "") {
          setConditionalMaintenanceKeys("lasttechnicalservice", {
            ...(condition_maintenance?.lasttechnicalservice ?? {}),
            [field]: value[field],
          });
        } else {
          setConditionalMaintenanceKeys("lasttechnicalservice", {});
        }
        break;
    }
    dispatch(setConditionMaintenance(params));
  };

  /**
   *-----------------------------------------------
   * SET EQUIPMENT LIST
   *-----------------------------------------------
   * @param {String} argument
   */
  const setOptionalEquipmentIdsDataHandler = (argument) => {
    let newOptionalEquipmentList = [...optional_equipmentids];
    if (newOptionalEquipmentList.includes(argument)) {
      newOptionalEquipmentList = newOptionalEquipmentList.filter(
        (item) => item !== argument
      );
    } else {
      newOptionalEquipmentList = [...optional_equipmentids, argument];
    }
    dispatch(setOptionalEquipmentIds(newOptionalEquipmentList));
  };

  /**
   *-----------------------------------------------
   * SET AD DESCRIPTION
   *-----------------------------------------------
   * @param {String} argument
   * @param {String} value
   */
  const setDescriptionHandler = (value) => {
    dispatch(setAdDescription(value));
  };

  const setAdDataHandler = (argument, value) => {
    if (argument === "idmake" || argument === "idmodel") {
      const updatedGeneralData = removePropsFromObject(general_data, [
        "equipmentline",
      ]);
      dispatch(setGeneralData(updatedGeneralData));
    }
    dispatch(setRootAdData({ ...ad_data, [argument]: value }));
  };

  const checkboxChangeHandler = (arrName, value, key) => {
    if (arrName === "fuelTypeWithAll" || arrName === "bodyTypeWithAll") {
      const updatedGeneralData = removePropsFromObject(general_data, [
        "equipmentline",
      ]);
      dispatch(setGeneralData(updatedGeneralData));
    }
    dispatch(checkboxChange(arrName, value, key));
    setAdDataHandler(key, value);
  };

  /**
   *-----------------------------------------------
   * FETCH NECESSARRY DATA ON PAGE INIT
   *-----------------------------------------------
   */
  useEffect(() => {
    dispatch(getInteriorColorForLocale());
    dispatch(getUpholsteryForLocale());
    dispatch(getConditionForLocale());
    dispatch(getDoors());
    dispatch(getSeats());
    dispatch(getBodyColorForLocale());
    dispatch(getDriveType());
  }, []);

  useEffect(() => {
    dispatch(getInteriorColorForLocale());
    dispatch(getUpholsteryForLocale());
    dispatch(getConditionForLocale());
    dispatch(getDoors());
    dispatch(getSeats());
    dispatch(getBodyColorForLocale());
    dispatch(getDriveType());
  }, [idlocale]);

  return (
    <div className="sell-vehicledetails">
      <h4 className="subtitle">{t("sell:main-vehicle-data")}</h4>
      <div className="mainvehicledata borderbottom">
        <FormControl
          inputType="select"
          label={t("common:make")}
          data={make}
          handleChange={(event, newValue) =>
            setAdDataHandler("idmake", newValue ? newValue.idmake : "")
          }
          value={make.find((item) => item.idmake === ad_data.idmake)}
        />
        <FormControl
          inputType="select"
          label={t("common:model")}
          data={car_model}
          handleChange={(event, newValue) =>
            setAdDataHandler("idmodel", newValue ? newValue.idmodel : "")
          }
          value={car_model.find((item) => item.idmodel === ad_data.idmodel)}
        />
        <FormControl
          inputType="select"
          label={t("common:fuel-type")}
          data={fuelTypeWithAll}
          handleChange={(event, newValue) =>
            checkboxChangeHandler(
              "fuelTypeWithAll",
              newValue.idfueltype,
              "idfueltype"
            )
          }
          value={fuelTypeWithAll.find(
            (item) => item.idfueltype === ad_data.idfueltype
          )}
        />
        <FormControl
          inputType="select"
          label={t("common:body-type")}
          data={bodyTypeWithAll}
          handleChange={(event, newValue) =>
            checkboxChangeHandler(
              "bodyTypeWithAll",
              newValue.idbodytype,
              "idbodytype"
            )
          }
          value={bodyTypeWithAll.find(
            (item) => item.idbodytype === ad_data.idbodytype
          )}
        />
        <FormControl
          label={t("common:power")}
          className="input-with-abbreviation gray-background"
        >
          <TextField
            name="power"
            placeholder={t("common:enter")}
            variant="outlined"
            value={general_data?.power ? general_data.power : ""}
            onChange={(event) =>
              setGeneralDataHandler("power", parseInt(event.target.value))
            }
            InputProps={{
              endAdornment: <span className="abbreviation">KW</span>,
              inputComponent: NumberFormatCustom,
            }}
          />
        </FormControl>
        <FormControl
          label={t("common:mileage")}
          className="input-with-abbreviation"
        >
          <TextField
            placeholder={t("common:type")}
            variant="outlined"
            value={ad_data.mileage}
            onChange={(event) =>
              setAdDataHandler("mileage", parseInt(event.target.value))
            }
            InputProps={{
              endAdornment: <span className="abbreviation">km</span>,
              inputComponent: NumberFormatCustom,
            }}
          />
        </FormControl>
        <FormControl
          label={t("common:price")}
          className="input-with-abbreviation"
        >
          <TextField
            name="price"
            placeholder={t("common:type")}
            value={ad_data.price}
            variant="outlined"
            onChange={(event) =>
              setAdDataHandler("price", parseInt(event.target.value))
            }
            InputProps={{
              endAdornment: <span className="abbreviation">CHF</span>,
              inputComponent: NumberFormatCustom,
            }}
          />
        </FormControl>
        <FormControl
          inputType="select"
          label={t("common:transmission")}
          data={transmission}
          value={transmission.find(
            (item) => item.idtransmission === engine_environment?.idtransmission
          )}
          handleChange={(event, newValue) =>
            setEngineEnvironmentHandler(
              "idtransmission",
              newValue ? newValue.idtransmission : ""
            )
          }
        />
        <FormControl
          inputType="select"
          label={t("common:drivetrain")}
          data={driveTypeWithAll}
          value={driveTypeWithAll?.find(
            (item) => item.iddrivetype === engine_environment?.iddrivetype
          )}
          handleChange={(event, newValue) =>
            setEngineEnvironmentHandler(
              "iddrivetype",
              newValue ? newValue.iddrivetype : ""
            )
          }
        />
        <FormControl label={t("common:type-certificate")}>
          <TextField
            variant="outlined"
            placeholder={t("common:enter")}
            className="type-certificate"
            value={general_data?.targaCode24}
            disabled={true}
          />
        </FormControl>
        <FormControl
          className="place"
          label={t("common:location-of-the-vehicle")}
          inputType="textfield"
          value={vehicle_location?.place ? vehicle_location.place : ""}
          disabled={true}
        />
      </div>
      <h4 className="subtitle">{t("sell:general-data")}</h4>
      <div className="general-data borderbottom">
        {motors.length > 0 ? (
          <FormControl
            name="equipmentline"
            className="wider-general-data"
            data={motors}
            inputType="select"
            label={t("common:equipment-line")}
            handleChange={(event, newValue) =>
              setGeneralDataHandler(
                "equipmentline",
                newValue ? newValue.idmotor.toString() : ""
              )
            }
            value={motors.find(
              (item) =>
                item.idmotor.toString() ===
                (general_data?.equipmentline
                  ? general_data.equipmentline
                  : null)
            )}
          />
        ) : (
          <FormControl
            name="equipmentline"
            type="text"
            className="wider-general-data gray-background"
            placeholder={t("common:equipment-line")}
            inputType="textfield"
            label={t("common:equipment-line")}
            value={
              general_data?.equipmentline ? general_data.equipmentline : ""
            }
            handleChange={(event) =>
              setGeneralDataHandler("equipmentline", event.target.value)
            }
          />
        )}
        <FormControl
          name="variant"
          type="text"
          className="wider-general-data gray-background"
          placeholder={t("sell:variant-placeholder")}
          inputType="textfield"
          label={t("common:variant")}
          value={general_data?.variant ? general_data.variant : ""}
          handleChange={(event) =>
            setGeneralDataHandler("variant", event.target.value)
          }
        />
        <FormControl label={t("common:body-color")}>
          <ColorPicker
            id={"idbodycolor"}
            colors={bodyColors}
            value={general_data?.idbodycolor ? general_data.idbodycolor : ""}
            changeHandler={(event, newValue) =>
              setGeneralDataHandler(
                "idbodycolor",
                newValue?.idbodycolor ? newValue.idbodycolor : ""
              )
            }
          />
        </FormControl>
      </div>
      <Accordion>
        <AccordionSummary
          expandIcon={ArrowDown}
          aria-controls="headline"
          id="headline"
        >
          <h4 className="subtitle">{t("sell:headline-text")}</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="headlinearea">
            <h3 className="mb-0">
              {makename} {modelname}
            </h3>
            <h3>
              {equipmentLinePreview} {general_data?.variant ?? ""}
            </h3>
            <div className="onerow">
              <img className="image" src={IMark} />
              <div>
                <p>{t("sell:vehicledescription1")}</p>
                <p>{t("sell:vehicledescription2")}</p>
                <p>{t("sell:vehicledescription3")}</p>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={ArrowDown}
          aria-controls="aestetic"
          id="aestetic"
        >
          <h4 className="subtitle">{t("sell:aesthetics&functionality")}</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mainvehicledata">
            <FormControl
              inputType="select"
              label={t("common:condition")}
              data={condition}
              value={condition.find(
                (item) => item.idcondition === vehicle_data?.idcondition
              )}
              handleChange={(_, newValue) => {
                setVehicleDataHandler(
                  "idcondition",
                  newValue ? newValue.idcondition : ""
                );
              }}
            />
            <FormControl
              inputType="checkbox"
              label={t("sell:metalic-color")}
              value={vehicle_data?.metalliccolor ? true : false}
              handleChange={() => {
                setVehicleDataHandler(
                  "metalliccolor",
                  vehicle_data?.metalliccolor ? false : true
                );
              }}
            />
            <FormControl
              inputType="select"
              label={t("common:upholstery")}
              data={upholstery}
              value={upholstery.find(
                (item) => item.idupholstery === vehicle_data?.idupholstery
              )}
              handleChange={(_, newValue) => {
                setVehicleDataHandler(
                  "idupholstery",
                  newValue ? newValue.idupholstery : ""
                );
              }}
            />
            <FormControl label={t("common:interior-color")}>
              <ColorPicker
                id={"idinteriorcolor"}
                label="interiorColor"
                colors={interiorcolor}
                value={
                  vehicle_data?.idinteriorcolor
                    ? vehicle_data.idinteriorcolor
                    : ""
                }
                changeHandler={(event, newValue) =>
                  setVehicleDataHandler(
                    "idinteriorcolor",
                    newValue?.idinteriorcolor ? newValue.idinteriorcolor : null
                  )
                }
              />
            </FormControl>
            <FormControl
              inputType="select"
              label={t("common:door-count")}
              disabled={doors.length === 0}
              data={doors}
              value={doors.find(
                (item) => item.iddoors === vehicle_data?.doorcount
              )}
              handleChange={(event, newValue) =>
                setVehicleDataHandler(
                  "doorcount",
                  newValue ? newValue.iddoors : ""
                )
              }
            />
            <FormControl
              inputType="select"
              label={t("common:number-of-seats")}
              data={seats}
              value={seats.find(
                (item) => item.idseats === vehicle_data?.numberofseats
              )}
              handleChange={(event, newValue) =>
                setVehicleDataHandler(
                  "numberofseats",
                  newValue ? newValue.idseats : ""
                )
              }
            />
            <FormControl
              inputType="checkbox"
              label={t("common:negotiable-price")}
              value={vehicle_data?.negotiableprice ? true : false}
              handleChange={() =>
                setVehicleDataHandler(
                  "negotiableprice",
                  vehicle_data?.negotiableprice ? false : true
                )
              }
            />
            <FormControl
              inputType="checkbox"
              label={t("common:indicate-vat")}
              value={vehicle_data?.indicatevat ? true : false}
              handleChange={() =>
                setVehicleDataHandler(
                  "indicatevat",
                  vehicle_data?.indicatevat ? false : true
                )
              }
            />
            <FormControl
              inputType="checkbox"
              label={t("common:alloy-wheels")}
              value={vehicle_data?.alloywheels ? true : false}
              handleChange={() =>
                setVehicleDataHandler(
                  "alloywheels",
                  vehicle_data?.alloywheels ? false : true
                )
              }
            />
            <FormControl
              inputType="select"
              label={t("common:trailer-hitch")}
              data={trailerHitch}
              value={trailerHitch.find(
                (item) => item.idtrailerhitch === vehicle_data?.trailerhitch
              )}
              handleChange={(event, newValue) =>
                setVehicleDataHandler(
                  "trailerhitch",
                  newValue ? newValue.idtrailerhitch : ""
                )
              }
            />
            <FormControl
              inputType="select"
              label={t("common:trailer-load")}
              data={trailerLoad}
              value={trailerLoad.find(
                (item) => item.idtrailerload === vehicle_data?.trailerload
              )}
              handleChange={(event, newValue) =>
                setVehicleDataHandler(
                  "trailerload",
                  newValue ? newValue.idtrailerload : ""
                )
              }
            />
            <FormControl
              label={t("common:total-weight")}
              className="input-with-abbreviation"
            >
              <TextField
                placeholder={t("common:enter")}
                value={vehicle_data?.totalweight ?? ""}
                variant="outlined"
                onChange={(event) =>
                  setVehicleDataHandler(
                    "totalweight",
                    parseInt(event.target.value)
                  )
                }
                InputProps={{
                  endAdornment: <span className="abbreviation">kg</span>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FormControl>
            <FormControl
              label={t("common:payload")}
              className="input-with-abbreviation"
            >
              <TextField
                placeholder={t("common:enter")}
                value={vehicle_data?.payload ?? ""}
                variant="outlined"
                onChange={(event) =>
                  setVehicleDataHandler("payload", parseInt(event.target.value))
                }
                InputProps={{
                  endAdornment: <span className="abbreviation">kg</span>,
                  inputComponent: NumberFormatCustom,
                }}
              />
            </FormControl>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={ArrowDown}
          aria-controls="engineenvironment"
          id="engineenvironment"
        >
          <h4 className="subtitle">{t("sell:engine&enviroment")}</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mainvehicledata">
            <FormControl
              inputType="select"
              data={[1, 2, 3]}
              label={t("common:engine-type")}
              disabled={true}
            />
            <FormControl
              inputType="select"
              label={t("common:number-of-gears")}
              disabled={gears.length === 0}
              data={gears}
              value={gears.find(
                (item) => item.idgears === engine_environment.numberofgears
              )}
              handleChange={(event, newValue) =>
                setEngineEnvironmentHandler(
                  "numberofgears",
                  newValue ? newValue.idgears : ""
                )
              }
            />
            <FormControl
              inputType="select"
              label={t("common:cylinder")}
              disabled={numberofcylinders.length === 0}
              data={numberofcylinders}
              value={numberofcylinders.find(
                (item) =>
                  item.numberofcylinders === engine_environment?.cylinder
              )}
              handleChange={(event, newValue) =>
                setEngineEnvironmentHandler(
                  "cylinder",
                  newValue ? newValue.numberofcylinders : ""
                )
              }
            />
            <FormControl
              inputType="select"
              label={t("common:engine-size")}
              data={[]}
              disabled={true}
            />
            <FormControl
              inputType="select"
              label={t("common:kerb-weight")}
              disabled={curbweight.length === 0}
              data={curbweight}
              value={curbweight.find(
                (item) => item.curbweight === engine_environment?.kerbweight
              )}
              handleChange={(event, newValue) =>
                setEngineEnvironmentHandler(
                  "kerbweight",
                  newValue ? newValue.curbweight : ""
                )
              }
            />
            <FormControl
              inputType="select"
              label={t("common:emission-class")}
              data={[]}
              disabled={true}
            />
            <FormControl
              inputType="select"
              label={t("common:emission-sticker")}
              data={[]}
              disabled={true}
            />
            <FormControl
              label={t("sell:fuel-consumption-comb")}
              className="input-with-abbreviation"
            >
              <TextField
                type="number"
                variant="outlined"
                placeholder={t("common:type")}
                value={
                  fuel_consumption?.combined ? fuel_consumption.combined : ""
                }
                onKeyDown={doNotShowLetterE}
                onChange={(event) =>
                  setFuelConsumptionDataHandler(
                    "combined",
                    parseFloat(event.target.value)
                  )
                }
                InputProps={{
                  endAdornment: <span className="abbreviation">l/100km</span>,
                }}
              />
            </FormControl>
            <FormControl
              label={t("sell:fuel-consumption-urban")}
              className="input-with-abbreviation"
            >
              <TextField
                type="number"
                variant="outlined"
                placeholder={t("common:type")}
                value={fuel_consumption?.urban ? fuel_consumption.urban : ""}
                onKeyDown={doNotShowLetterE}
                onChange={(event) =>
                  setFuelConsumptionDataHandler(
                    "urban",
                    parseFloat(event.target.value)
                  )
                }
                InputProps={{
                  endAdornment: <span className="abbreviation">l/100km</span>,
                }}
              />
            </FormControl>
            <FormControl
              label={t("sell:fuel-cons-extra-urban")}
              className="input-with-abbreviation"
            >
              <TextField
                placeholder={t("common:type")}
                type="number"
                variant="outlined"
                value={
                  fuel_consumption?.extraurban
                    ? fuel_consumption.extraurban
                    : ""
                }
                onKeyDown={doNotShowLetterE}
                onChange={(event) =>
                  setFuelConsumptionDataHandler(
                    "extraurban",
                    parseFloat(event.target.value)
                  )
                }
                InputProps={{
                  endAdornment: <span className="abbreviation">l/100km</span>,
                }}
              />
            </FormControl>
            <FormControl
              label={t("sell:co2-emission")}
              className="input-with-abbreviation"
            >
              <TextField
                variant="outlined"
                type="number"
                placeholder={t("common:type")}
                value={
                  engine_environment?.co2emission
                    ? engine_environment.co2emission
                    : ""
                }
                onChange={(event) =>
                  setEngineEnvironmentHandler(
                    "co2emission",
                    parseFloat(event.target.value)
                  )
                }
                onKeyDown={doNotShowLetterE}
                InputProps={{
                  endAdornment: <span className="abbreviation">g/km</span>,
                }}
              />
            </FormControl>
            <FormControl
              inputType="select"
              label={t("adDetails:energy-efficiency-class")}
              data={energyEfficienceData}
              handleChange={(_, newValue) =>
                setEngineEnvironmentHandler(
                  "energyefficiencyclass",
                  newValue ? newValue.value : ""
                )
              }
              value={energyEfficienceData.find(
                (item) =>
                  item.name === engine_environment?.energyefficiencyclass
              )}
            />
            <FormControl
              inputType="select"
              label={t("adDetails:euro-norm")}
              data={euroNorm}
              handleChange={(event, newValue) =>
                setEngineEnvironmentHandler(
                  "ideuronorm",
                  newValue ? newValue.ideuronorm : ""
                )
              }
              value={euroNorm.find(
                (item) => item.ideuronorm === engine_environment?.ideuronorm
              )}
            />
            <FormControl
              inputType="checkbox"
              label={t("common:particulate-filter")}
              value={engine_environment?.particulatefilter ? true : false}
              handleChange={() =>
                setEngineEnvironmentHandler(
                  "particulatefilter",
                  engine_environment?.particulatefilter ? false : true
                )
              }
            />
            <FormControl
              inputType="checkbox"
              label={t("common:catalytic-converter")}
              value={engine_environment?.catalyticconverter ? true : false}
              handleChange={() =>
                setEngineEnvironmentHandler(
                  "catalyticconverter",
                  engine_environment?.catalyticconverter ? false : true
                )
              }
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="conditionalMaintenanceAccordion"
        expanded={
          Object.keys(errorDates).length > 0 || expandConditionalAndMaintenance
        }
        onChange={(e) => {
          if (Object.keys(errorDates).length > 0) {
            return;
          }
          setExpandConditionalAndMaintenance(!expandConditionalAndMaintenance);
        }}
      >
        <AccordionSummary
          expandIcon={ArrowDown}
          aria-controls="conditionmaintenance"
          id="conditionmaintenance"
        >
          <h4 className="subtitle">{t("sell:condition&maintenance")}</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mainvehicledata">
            <FormControl
              inputType="checkbox"
              label={t("common:recent-techincal-service")}
              value={
                condition_maintenance.recenttechnicalservice
                  ? condition_maintenance.recenttechnicalservice
                  : false
              }
              handleChange={() =>
                setConditionMaintenanceDataHandler(
                  "recenttechnicalservice",
                  !condition_maintenance.recenttechnicalservice
                )
              }
            />
            <div className="date">
              <label>{t("common:last-technical-service")}</label>
              <div className="date-controls">
                <Select
                  data={numericMonthsWithCancel}
                  placeholder={t("common:month")}
                  name="monthlastTechnicalService"
                  handleChange={(event, newValue) =>
                    setConditionMaintenanceDataHandler(
                      "monthlastTechnicalService",
                      {
                        ...(condition_maintenance?.lasttechnicalservice ?? {}),
                        month: newValue.month,
                      }
                    )
                  }
                  defaultValue={
                    condition_maintenance?.lasttechnicalservice?.month === ""
                      ? null
                      : numericMonthsWithCancel.find(
                          (item) =>
                            item.month ===
                            condition_maintenance?.lasttechnicalservice?.month
                        )
                  }
                />
                <Select
                  data={yearsWithCancel}
                  placeholder={t("common:year")}
                  name="yearslastTechnicalService"
                  handleChange={(event, newValue) =>
                    setConditionMaintenanceDataHandler(
                      "yearslastTechnicalService",
                      {
                        ...(condition_maintenance?.lasttechnicalservice ?? {}),
                        year: newValue.year,
                      }
                    )
                  }
                  defaultValue={
                    condition_maintenance?.lasttechnicalservice?.year === ""
                      ? null
                      : yearsWithCancel.find(
                          (item) =>
                            item.year ===
                            condition_maintenance?.lasttechnicalservice?.year
                        )
                  }
                />
              </div>
              {errorDates?.lasttechnicalservice && (
                <div className="error-message">
                  {errorDates.lasttechnicalservice?.message}
                </div>
              )}
            </div>
            <FormControl
              inputType="checkbox"
              label={`${t("common:from")} MFK`}
              value={
                condition_maintenance.frommfk
                  ? condition_maintenance.frommfk
                  : false
              }
              handleChange={() =>
                setConditionMaintenanceDataHandler(
                  "frommfk",
                  !condition_maintenance.frommfk
                )
              }
            />
            <FormControl
              inputType="checkbox"
              label={t("common:guarantee")}
              className="guarantee"
              value={
                condition_maintenance.guarantee
                  ? condition_maintenance.guarantee
                  : false
              }
              handleChange={() =>
                setConditionMaintenanceDataHandler(
                  "guarantee",
                  !condition_maintenance.guarantee
                )
              }
            />
            <FormControl
              inputType="checkbox"
              label={t("common:direct-parallel-import")}
              value={
                condition_maintenance.directimport
                  ? condition_maintenance.directimport
                  : false
              }
              handleChange={() =>
                setConditionMaintenanceDataHandler(
                  "directimport",
                  !condition_maintenance.directimport
                )
              }
            />
            <FormControl
              inputType="checkbox"
              label={t("common:handicapped-accessible")}
              value={
                condition_maintenance.handicappedaccessible
                  ? condition_maintenance.handicappedaccessible
                  : false
              }
              handleChange={() =>
                setConditionMaintenanceDataHandler(
                  "handicappedaccessible",
                  !condition_maintenance.handicappedaccessible
                )
              }
            />
            <FormControl
              inputType="checkbox"
              label={t("common:damaged-vehicle")}
              value={
                condition_maintenance.damagedvehicle
                  ? condition_maintenance.damagedvehicle
                  : false
              }
              handleChange={() =>
                setConditionMaintenanceDataHandler(
                  "damagedvehicle",
                  !condition_maintenance.damagedvehicle
                )
              }
            />
            <FormControl
              inputType="checkbox"
              label={t("common:non-smoking-vehicle")}
              value={
                condition_maintenance.nonsmokercar
                  ? condition_maintenance.nonsmokercar
                  : false
              }
              handleChange={() =>
                setConditionMaintenanceDataHandler(
                  "nonsmokercar",
                  !condition_maintenance.nonsmokercar
                )
              }
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={ArrowDown}
          aria-controls="optionalequipmentids"
          id="optionalequipmentids"
        >
          <h4 className="subtitle">{t("sell:equipment-list")}</h4>
        </AccordionSummary>
        <AccordionDetails>
          <h4>{t("sell:equipment")}</h4>
          <div className="equipment-list">
            {equipmentList.map((item) => (
              <FormControl
                inputType="checkbox"
                key={item.id}
                label={t(`equipmentList:${item.label}`)}
                value={optional_equipmentids.some(
                  (equipmentIds) =>
                    equipmentIds === item.id ||
                    equipmentIds === item.id.toString()
                )}
                handleChange={() => {
                  setOptionalEquipmentIdsDataHandler(item.id.toString());
                }}
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={ArrowDown}
          aria-controls="conditionmaintenance"
          id="conditionmaintenance"
        >
          <h4 className="subtitle">{t("sell:detailes-vehicle-description")}</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="textarea">
            <label>{t("sell:description")}</label>
            <textarea
              value={ad_description ? ad_description : ""}
              placeholder={t("common:type")}
              onChange={(event) => setDescriptionHandler(event.target.value)}
              name="description"
              id="description"
              cols="30"
              rows="10"
            ></textarea>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const stateToProps = (state) => {
  const {
    make,
    car_model,
    fuelTypeWithAll,
    bodyTypeWithAll,
    power,
    condition,
    doors,
    seats,
    motors,
    upholstery,
    transmission,
    gearbox,
    gears,
    curbweight,
    numberofcylinders,
    ad_data,
    vehicle_location,
    general_data,
    vehicle_data,
    condition_maintenance,
    optional_equipmentids,
    engine_environment,
    fuel_consumption,
    ad_description,
    bodyColors,
    idlocale,
    interiorcolor,
    energyEfficience,
    driveTypeWithAll,
  } = state.car;

  const energyEfficienceData = energyEfficience.map((item) => ({
    name: item.name,
    value: item.name,
  }));

  return {
    make,
    car_model,
    fuelTypeWithAll,
    bodyTypeWithAll,
    power,
    condition,
    doors,
    seats,
    motors,
    upholstery,
    transmission,
    gearbox,
    gears,
    curbweight,
    numberofcylinders,
    ad_data,
    vehicle_location,
    general_data,
    vehicle_data,
    condition_maintenance,
    optional_equipmentids,
    engine_environment,
    fuel_consumption,
    ad_description,
    bodyColors,
    idlocale,
    interiorcolor,
    energyEfficienceData,
    driveTypeWithAll,
  };
};

export default connect(stateToProps)(VehicleDetails);
