import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { renderSavedSearch } from "../../store/actions";
import history from "../../lib/history";
import Ads from "../../components/ads";
import "./index.css";

const SavedSearch = (props) => {
  const { dispatch } = props;
  const { t, i18n } = useTranslation(["common", "savedSearch"]);
  const [savedSearches, setSavedSearches] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortLabel, setSortLabel] = useState("common:oldest");
  const [loading, setLoading] = useState(true);

  const renderSavedSearchHandler = (searchData) => {
    dispatch(renderSavedSearch(searchData));
    history.push("/results");
  };

  const deleteHandler = (deleteIndex) => {
    const savedSearchData = getSavedSearches();
    const filteredData = savedSearchData.filter(
      (item, index) => index !== deleteIndex
    );
    setSavedSearches(filteredData);
    localStorage.setItem("searches", JSON.stringify(filteredData));
  };

  const openSortMenu = (event) => setAnchorEl(event.currentTarget);

  const handleSort = (idSort) => {
    if (idSort === 0) {
      const data = savedSearches.sort(
        (a, b) => Date.parse(a.date) - Date.parse(b.date)
      );
      setSavedSearches(data);
      setSortLabel("common:oldest");
    }
    if (idSort === 1) {
      const data = savedSearches.sort(
        (a, b) => Date.parse(b.date) - Date.parse(a.date)
      );
      setSavedSearches(data);
      setSortLabel("common:newest");
    }
    setAnchorEl(null);
  };

  const getSavedSearches = () => JSON.parse(localStorage.getItem("searches"));

  useEffect(() => {
    const savedSearchData = getSavedSearches();
    if (savedSearchData === null) {
      setSavedSearches([]);
    } else {
      setSavedSearches(savedSearchData);
    }
  }, []);

  useEffect(() => {
    if (savedSearches === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [savedSearches]);

  return (
    <div className="saved-search">
      <div className="saved-search-aside"></div>
      <main>
        <Ads
          data={savedSearches ? savedSearches : []}
          deleteHandler={deleteHandler}
          renderHandler={renderSavedSearchHandler}
          title={t("savedSearch:tap-your-saved-search")}
          sortLabel={sortLabel}
          openSort={openSortMenu}
          anchorEl={anchorEl}
          handleSort={handleSort}
          dropdownData={["common:oldest", "common:newest"]}
          path="savedsearch"
          loading={loading}
        />
      </main>
    </div>
  );
};
export default connect()(SavedSearch);
