import React from "react";
import { useTranslation } from "react-i18next";
import CheckMark from "../../../assets/images/check-mark.svg";
import Carindex from "../../../assets/images/new-logo.svg";
import "./index.css";

const AdDetected = ({ isCreateAdPage }) => {
  const { t } = useTranslation("sell");

  return (
    <div className="ad-detected">
      <div className="header">
        <img src={CheckMark} alt="check-mark" />
        <span>{t("sell:approved")}</span>
      </div>
      <h5>{t("sell:congratulation")}</h5>
      <p>
        {isCreateAdPage
          ? t("sell:ad-successfully-detected")
          : t("sell:ad-successfully-updated")}
      </p>
      <div className="info">
        <p>{t("sell:ad-detected-instruction-pt1")}</p>
        <p>{t("sell:ad-detected-instruction-pt2")}</p>
      </div>
      <img src={Carindex} alt="carindex-log" />
    </div>
  );
};

export default AdDetected;
