import React from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ArrowDown } from "../../../components/svg-icons/index";

import "./index.css";

const SafetyInstructions = () => {
  const { t } = useTranslation(["footer"]);

  return (
    <div className="safety-instructions">
      <header>
        <h3>{t("footer:safety-instructions-header")}</h3>
        <p>{t("footer:safety-instructions-p-1")}</p>
        <p>{t("footer:safety-instructions-p-2")}</p>
      </header>
      <main>
        <Accordion>
          <AccordionSummary
            expandIcon={ArrowDown}
            aria-controls="headline"
            id="headline"
          >
            <h4 className="subtitle">{t("footer:safety-instructions-1")}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              {t("footer:safety-instructions-1-1")}{" "}
              <span>{t("footer:safety-instructions-1-red")}</span>{" "}
              {t("footer:safety-instructions-1-2")}
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={ArrowDown}
            aria-controls="headline"
            id="headline"
          >
            <h4 className="subtitle">{t("footer:safety-instructions-2")}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p>{t("footer:safety-instructions-2-1")}</p>
            <p>{t("footer:safety-instructions-2-2")}</p>
            <p>{t("footer:safety-instructions-2-3")}</p>
            <p>{t("footer:safety-instructions-2-4")}</p>
            <p>{t("footer:safety-instructions-2-5")}</p>
            <p>{t("footer:safety-instructions-2-6")}</p>
            <p>
              {t("footer:safety-instructions-2-7")}{" "}
              <span>{t("footer:safety-instructions-2-red")}</span>{" "}
              {t("footer:safety-instructions-2-8")}
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={ArrowDown}
            aria-controls="headline"
            id="headline"
          >
            <h4 className="subtitle">{t("footer:safety-instructions-3")}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <p>{t("footer:safety-instructions-3-1")}</p>
            <p>{t("footer:safety-instructions-3-2")}</p>
            <p>{t("footer:safety-instructions-3-3")}</p>
            <p>{t("footer:safety-instructions-3-4")}</p>
            <p>
              {t("footer:safety-instructions-3-5")}{" "}
              <span>{t("footer:safety-instructions-2-red")}</span>{" "}
              {t("footer:safety-instructions-2-8")}
            </p>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={ArrowDown}
            aria-controls="headline"
            id="headline"
          >
            <h4 className="subtitle">{t("footer:safety-instructions-4")}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <a href="https://www.skppsc.ch/de/" target="_blank">
              {t("footer:safety-instructions-4-1")}
            </a>
            <a
              href="https://www.ncsc.admin.ch/ncsc/de/home.html"
              target="_blank"
            >
              {t("footer:safety-instructions-4-2")}
            </a>
            <a href="https://www.antiphishing.ch/en/" target="_blank">
              {t("footer:safety-instructions-4-3")}
            </a>
          </AccordionDetails>
        </Accordion>
      </main>
    </div>
  );
};

export default SafetyInstructions;
