import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getEquipment } from "../../../../data";
import { ArrowDownRed, ArrowUpRed } from "../../../../components/svg-icons";
import "./index.css";

const equipmentList = getEquipment();

const Equipment = (props) => {
  const { adData } = props;
  const { t } = useTranslation(["common", "adDetails", "equipmentList"]);
  const [collapseEquipment, setCollapseEquipment] = useState(true);

  return adData?.optionalequipmentids?.length > 0 ? (
    <div>
      <div className="width-768">
        <div
          className={`${
            adData?.optionalequipmentids?.length > 12 && collapseEquipment
              ? "eq-collapsed"
              : "eq-expand"
          }`}
        >
          <ul>
            {adData?.optionalequipmentids.map((id) => (
              <li key={id}>{t(`equipmentList:${equipmentList[id]?.label}`)}</li>
            ))}
          </ul>
        </div>
        {adData?.optionalequipmentids?.length > 12 ? (
          <span
            className="show-more"
            onClick={() => setCollapseEquipment(!collapseEquipment)}
          >
            <p className="show-more-link">
              {collapseEquipment ? ArrowDownRed : ArrowUpRed}
              {collapseEquipment
                ? t("common:show-more")
                : t("common:show-less")}
            </p>
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="width-1024">
        <div
          className={`${
            adData?.optionalequipmentids?.length > 15 && collapseEquipment
              ? "eq-collapsed"
              : "eq-expand"
          }`}
        >
          <ul>
            {adData?.optionalequipmentids.map((id) => (
              <li key={id}>{t(`equipmentList:${equipmentList[id]?.label}`)}</li>
            ))}
          </ul>
        </div>
        {adData?.optionalequipmentids?.length > 15 ? (
          <span
            className="show-more"
            onClick={() => setCollapseEquipment(!collapseEquipment)}
          >
            <p className="show-more-link">
              {collapseEquipment ? ArrowDownRed : ArrowUpRed}
              {collapseEquipment
                ? t("common:show-more")
                : t("common:show-less")}
            </p>
          </span>
        ) : (
          ""
        )}
      </div>
      <div className="width-1920">
        <div
          className={`${
            adData?.optionalequipmentids?.length > 16 && collapseEquipment
              ? "eq-collapsed"
              : "eq-expand"
          }`}
        >
          <ul>
            {adData?.optionalequipmentids.map((id) => (
              <li key={id}>{t(`equipmentList:${equipmentList[id]?.label}`)}</li>
            ))}
          </ul>
        </div>
        {adData?.optionalequipmentids?.length > 16 ? (
          <span
            className="show-more"
            onClick={() => setCollapseEquipment(!collapseEquipment)}
          >
            <p className="show-more-link">
              {collapseEquipment ? ArrowDownRed : ArrowUpRed}
              {collapseEquipment
                ? t("common:show-more")
                : t("common:show-less")}
            </p>
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    <p className="energy-data-label">{t("adDetails:eq-not-selected")}</p>
  );
};
export default Equipment;
