import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import GoogleMapReact from "google-map-react";
import { acceptOnlyNumbers } from "../../lib/utils";
import Api from "../../lib/api";
import { defaultLocation, getRegionsSwiss } from "../../data";
import { TYPES_OF_MERCHANT } from "../../types";
import { Close } from "../svg-icons";
import Button from "../button";
import FormControl from "../form-control";
import Radio from "../radio";
import FindOnMap from "../../assets/shapes/find-on-map-svgrepo-com.svg";
import "./index.css";

const regionSwiss = getRegionsSwiss();

const merchantForm = (props) => {
  const {
    profileData,
    phoneTypes,
    inputChangeHandler,
    addPhoneNumber,
    removePhoneNumber,
    errorMessages,
    setAddressData,
    findOnMap,
    changeMap,
  } = props;
  const { t } = useTranslation(["common", "profile"]);
  const loadMap = (map, maps) => {
    let marker = new maps.Marker({
      position: {
        lat: profileData?.location?.latitude
          ? profileData?.location?.latitude
          : defaultLocation.lat,
        lng: profileData?.location?.longitude
          ? profileData?.location?.longitude
          : defaultLocation.lng,
      },
      map,
      draggable: true,
    });
    marker.addListener("dragend", async () => {
      const data = {
        address_data: {},
      };
      const lat = marker.getPosition().lat();
      const lng = marker.getPosition().lng();
      data.location = {
        latitude: lat,
        longitude: lng,
      };
      try {
        const geocodeResponse = await Api.reverseGeocoding(lat, lng);
        geocodeResponse.results[0].address_components.forEach((item) => {
          if (item.types.includes("street_number")) {
            data.address_data.streetandnumber = item.long_name;
          }
          if (item.types.includes("route")) {
            data.address_data.streetandnumber =
              `${
                data?.address_data?.streetandnumber
                  ? data.address_data.streetandnumber + " "
                  : ""
              }` + item.long_name;
          }
          if (item.types.includes("locality")) {
            data.city = item.long_name;
          }
          if (item.types.includes("country")) {
            data.country = item.long_name;
          }
          if (item.types.includes("postal_code")) {
            data.address_data.zipcode = item.long_name;
          }
        });
        setAddressData(data);
      } catch (err) {
        return null;
      }
    });
  };

  return (
    <div className="contact-form">
      <FormControl className={"d-flex"}>
        <Radio
          name={"type"}
          value={0}
          checked={profileData.type === TYPES_OF_MERCHANT.MR}
          className={"radio-button-target"}
          label={t("profile:mr")}
          changeHandler={(e) =>
            inputChangeHandler(e.target.name, parseInt(e.target.value))
          }
        />
        <Radio
          name={"type"}
          value={1}
          className={"radio-button-target"}
          label={t("profile:ms")}
          checked={profileData.type === TYPES_OF_MERCHANT.MS}
          changeHandler={(e) =>
            inputChangeHandler(e.target.name, parseInt(e.target.value))
          }
        />
        <Radio
          name={"type"}
          value={2}
          className={"radio-button-target"}
          label={t("profile:company")}
          checked={profileData.type === TYPES_OF_MERCHANT.COMPANY}
          changeHandler={(e) =>
            inputChangeHandler(e.target.name, parseInt(e.target.value))
          }
        />
      </FormControl>
      <FormControl
        inputType="textfield"
        name="first_name"
        label={t("profile:first-name")}
        type="text"
        placeholder={t("common:type")}
        defaultValue={profileData?.first_name}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <FormControl
        inputType="textfield"
        name="last_name"
        label={t("profile:last-name")}
        type="text"
        placeholder={t("common:type")}
        defaultValue={profileData?.last_name}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <FormControl
        inputType="textfield"
        name="company"
        label={t("profile:company-aplicable")}
        type="text"
        placeholder={t("common:type")}
        defaultValue={profileData?.company}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <FormControl
        inputType="textfield"
        name="additional"
        label={t("profile:additional")}
        type="text"
        placeholder={t("common:type")}
        defaultValue={profileData?.additional}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <div className="map-wrapper">
        <p style={{ marginBottom: "15px" }}>{t("profile:drag-pin")}</p>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
          defaultCenter={{
            lat: profileData?.location?.latitude
              ? profileData?.location?.latitude
              : defaultLocation.lat,
            lng: profileData?.location?.longitude
              ? profileData?.location?.longitude
              : defaultLocation.lng,
          }}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
          key={changeMap}
        />
      </div>
      <div className="find-on-map-wrapper">
        <FormControl
          inputType="textfield"
          label={t("profile:streetandnumber")}
          name="streetandnumber"
          type="text"
          placeholder={t("common:type")}
          value={
            profileData?.address_data?.streetandnumber
              ? profileData.address_data.streetandnumber
              : ""
          }
          handleChange={(e) =>
            inputChangeHandler(e.target.name, e.target.value, "address")
          }
        />
        <img
          className="find-on-map"
          alt="find on map"
          src={FindOnMap}
          onClick={findOnMap}
        />
      </div>
      <div className="joined-inputs-1-2">
        <FormControl
          inputType="textfield"
          label={t("profile:zip-code")}
          name="zipcode"
          type="text"
          placeholder={t("common:type")}
          value={
            profileData?.address_data?.zipcode
              ? profileData.address_data.zipcode
              : ""
          }
          handleChange={(e) => {
            if (acceptOnlyNumbers(e.target.value)) {
              inputChangeHandler(e.target.name, e.target.value, "address");
            }
          }}
        />
        <FormControl
          inputType="textfield"
          label={t("profile:place")}
          name="city"
          type="text"
          id="city-scroll-target"
          errorMessage={t(`${errorMessages?.city ? errorMessages?.city : ""}`)}
          errorMessageClassName={"color-red"}
          placeholder={t("common:type")}
          value={profileData?.city ? profileData.city : ""}
          handleChange={(e) =>
            inputChangeHandler(e.target.name, e.target.value)
          }
        />
      </div>
      <div className="joined-inputs-1-2">
        <FormControl
          inputType="select"
          label={t("profile:region")}
          name="region"
          placeholder={t("common:type")}
          data={regionSwiss}
          value={
            profileData.country === "Switzerland"
              ? regionSwiss.find(
                  (region) => profileData.address_data.region === region.name
                )
              : ""
          }
          disabled={profileData.country === "Switzerland" ? false : true}
          handleChange={(e, newValue) =>
            inputChangeHandler(
              "region",
              newValue ? newValue.name : "",
              "address"
            )
          }
        />
        <FormControl
          inputType="textfield"
          label={t("profile:country")}
          name="country"
          type="text"
          id="country-scroll-target"
          errorMessage={t(
            `${errorMessages?.country ? errorMessages?.country : ""}`
          )}
          errorMessageClassName={"color-red"}
          placeholder={t("common:type")}
          value={profileData?.country ? profileData.country : ""}
          handleChange={(e) =>
            inputChangeHandler(e.target.name, e.target.value)
          }
        />
      </div>
      <>
        <p>{t("profile:phone-num-example")}</p>
        <div className="joined-inputs">
          <FormControl
            inputType="select"
            name="type"
            data={phoneTypes}
            value={phoneTypes.find(
              (type) =>
                type.name ===
                (!!profileData.phones ? profileData.phones[0].type : null)
            )}
            handleChange={(event, newValue) =>
              inputChangeHandler(
                "type",
                newValue ? newValue.name : null,
                "phone",
                0
              )
            }
          />
          <FormControl
            inputType="textfield"
            type="text"
            name="number"
            placeholder={t("common:type")}
            defaultValue={
              profileData.phones ? profileData.phones[0].number : ""
            }
            handleChange={(e) =>
              inputChangeHandler(e.target.name, e.target.value, "phone", 0)
            }
          />
        </div>
        {!!profileData.phones &&
          profileData.phones.map((item, index) => {
            if (index !== 0) {
              return (
                <div className="joined-inputs" key={item.id}>
                  <FormControl
                    inputType="select"
                    name="type"
                    data={phoneTypes}
                    value={phoneTypes.find(
                      (type) =>
                        type.name ===
                        (profileData.phones[index]
                          ? profileData.phones[index].type
                          : null)
                    )}
                    handleChange={(event, newValue) =>
                      inputChangeHandler(
                        "type",
                        newValue ? newValue.name : null,
                        "phone",
                        index
                      )
                    }
                  />
                  <FormControl
                    inputType="textfield"
                    name="number"
                    type="text"
                    placeholder={t("common:type")}
                    defaultValue={item.number}
                    handleChange={(e) =>
                      inputChangeHandler(
                        e.target.name,
                        e.target.value,
                        "phone",
                        index
                      )
                    }
                  />
                  {index > 0 && (
                    <Button
                      color="transparent"
                      className="close"
                      click={() => removePhoneNumber(item.id)}
                    >
                      <Close />
                    </Button>
                  )}
                </div>
              );
            }
          })}
      </>
      <Button
        color="transparent"
        className="add_another_phone"
        label={t("profile:add-another-phone")}
        click={addPhoneNumber}
        disable={!profileData.phones || profileData?.phones?.length === 1}
      />
      <FormControl
        inputType="textfield"
        label={t("profile:web-page")}
        name="website"
        type="text"
        placeholder={t("common:type")}
        defaultValue={profileData.website}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <p className="email-address">
        {t("profile:email-address")}:{" "}
        <span> {profileData?.email && profileData?.email} </span>
      </p>
      <Tooltip anchorSelect=".find-on-map" place="top">
        Synchronize the map with the address
      </Tooltip>
      <Button
        color="white"
        className="contact-btn"
        label={t("profile:contact-us-for-support")}
      />
    </div>
  );
};

export default merchantForm;
