export const REQUEST_ERROR = "REQUEST_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const SEND_FIREBASE_TOKEN = "SEND_FIREBASE_TOKEN";
export const SET_COMMON_PROP = "SET_COMMON_PROP";
export const SHOW_TOAST = "SHOW_TOAST";
export const RESET_TOAST = "RESET_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
export const INIT = "INIT";
export const GET_SOCIALS = "GET_SOCIALS";
export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const SET_COMMON_MODAL = "SET_COMMON_MODAL";
export const GET_TERMS = "GET_TERMS";
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";
export const GET_FAQ = "GET_FAQ";
export const INIT_FIREBASE = "INIT_FIREBASE";
export const CHANGE_CONNECTIONS_STATUS = "CHANGE_CONNECTIONS_STATUS";
export const CLEAR_INTERVAL = "CLEAR_INTERVAL";
export const LOCATION = "LOCATION";
export const INIT_FIREBASE_DATABASE = "INIT_FIREBASE_DATABASE";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_SEARCH = "SET_SEARCH";
export const ISHOMEROUTEACTIVE = "ISHOMEROUTEACTIVE";
export const SETPHOTOSTOUPLOAD = "SETPHOTOSTOUPLOAD";
export const UPLOAD_RESULT = "UPLOAD_RESULT";
export const UPLOAD_ERROR = "UPLOAD_ERROR";
export const RESET_UPLOAD_FINISHED = "RESET_UPLOAD_FINISHED";
export const SETADIMAGES = "SETADIMAGES";
export const DELETEPHOTOS = "DELETEPHOTOS";
export const DELETEPHOTOS_ERROR = "DELETEPHOTOS_ERROR";
export const DELETEPHOTOS_RESULT = "DELETEPHOTOS_RESULT";
export const CLEAR_COMMON_DATA = "CLEAR_COMMON_DATA";

//socket
export const BEGINUPLOAD = "BEGINUPLOAD";
export const BEGINUPLOAD_RESULT = "BEGINUPLOAD_RESULT";
export const BEGINUPLOAD_ERROR = "BEGINUPLOAD_ERROR";

export const ONUPLOADCOMPLETE = "ONUPLOADCOMPLETE";
export const ONUPLOADCOMPLETE_RESULT = "ONUPLOADCOMPLETE_RESULT";
export const ONUPLOADCOMPLETE_ERROR = "ONUPLOADCOMPLETE_ERROR";

export const CANCELUPLOAD = "CANCELUPLOAD";
export const CANCELUPLOAD_RESULT = "CANCELUPLOAD_RESULT";
export const CANCELUPLOAD_ERROR = "CANCELUPLOAD_ERROR";

export const SETADPHOTOS = "SETADPHOTOS";
export const REORDERPHOTOS = "REOREDERPHOTOS";
export const RESETADPHOTOS = "RESETADPHOTOS";
export const DETELEPHOTOFROMUPLOADARRAY = "DETELEPHOTOFROMUPLOADARRAY";
