import React from "react";
import { connect } from "react-redux";
import { beginUpload } from "../../../store/actions";
import { useTranslation } from "react-i18next";
import ShownContactInfo from "../../../components/user-info/index";
import Button from "../../../components/button";
import Dropzone from "react-dropzone";
import Avatar from "../../../components/avatar/index";
import { USER_TYPES } from "../../../types";
import "./index.css";

const ProfilePicture = (props) => {
  const acceptedFileTypes =
    "image/x-png, image/png, image/jpg, image/jpeg, image/gif";
  const { dispatch, avatar, chooseType } = props;
  const { t } = useTranslation(["common", "profile"]);
  const buttonLabel = avatar ? t("common:change") : t("profile:add-photo");

  const handleOnDrop = (files) => {
    const currentFile = files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        if (chooseType === USER_TYPES.STANDARD) {
          dispatch(beginUpload(1, reader.result));
        }
        if (chooseType === USER_TYPES.MERCHANT) {
          dispatch(beginUpload(4, reader.result));
        }
      },
      false
    );
    reader.readAsDataURL(currentFile);
  };
  return (
    <div className="profile-photo">
      <div>
        <h5>{t("profile:profile-photo")}</h5>
        <p>{t("profile:profile-photo-instruction-pt1")}</p>
        <p>{t("profile:profile-photo-instruction-pt2")}</p>
        <div>
          <div className="profilephoto-container">
            <Avatar customClass="big-avatar" />
            <Dropzone
              accept={acceptedFileTypes}
              multiple={false}
              onDrop={(files, rejectedFiles) =>
                handleOnDrop(files, rejectedFiles)
              }
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button
                      color="white"
                      className="changephoto"
                      label={buttonLabel}
                    />
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
      <ShownContactInfo>
        <Avatar customClass="small-avatar" circularSize={10} />
      </ShownContactInfo>
    </div>
  );
};

const stateToProps = (state) => {
  const { avatar, chooseType } = state.profile;
  return {
    avatar,
    chooseType,
  };
};

export default connect(stateToProps)(ProfilePicture);
