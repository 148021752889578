import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getCarModel,
  resetAdProps,
  setRootAdData,
  setGeneralData,
} from "../../../store/actions";
import { getMonthsData, years } from "../../../data";
import axios from "axios";
import HelpInfo from "./components/help-modal";
import Button from "../../../components/button/index";
import Select from "../../../components/select/index";
import FormControl from "../../../components/form-control/";

import "./index.css";

const IdentifyVehicle = (props) => {
  const { dispatch, profileData, redirect, ad_data, car_model, general_data } =
    props;
  const { t } = useTranslation(["common", "sell"]);
  const [model, setModel] = useState(null);
  const [targaCode, setTargaCode] = useState("");
  const [isHelpWindowOpen, setHelpWindowOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const months = getMonthsData();

  const continueWithoutRegistration = () => {
    dispatch(resetAdProps());
    redirect(true);
  };

  const getTargaData = () => {
    const uid = profileData?.uid;
    const uidString = uid?.toString();
    const authToken = localStorage.getItem("token");
    const reqData = JSON.stringify({
      targaCode: general_data?.targaCode24,
      uid: uidString,
      authToken,
    });
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .post("https://carindex.piip.io:8099/getCar", reqData, { headers })
      .then((response) => {
        const { idmake, idmodel } = response.data;
        const modelObject = idmodel ? { idmodel } : {};
        dispatch(
          setRootAdData({
            ...ad_data,
            ...modelObject,
            idmake,
          })
        );
        redirect(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openModal = (imageName) => {
    setModalContent(imageName);
    setHelpWindowOpen(true);
  };

  const closeModal = () => {
    setHelpWindowOpen(false);
  };

  useEffect(() => {
    if (ad_data.idmake) {
      dispatch(getCarModel(ad_data.idmake));
    }
  }, [ad_data]);

  return (
    <div className="identify-vehicle">
      <div>
        <div>
          <h4>{t("sell:with-registration-card")}</h4>
          <h5 className="italic">{t("sell:advantage-less-effort")}</h5>
        </div>
        <div className="form-container">
          <div className="document-section-forms">
            <div className="sec-18">
              <p>{t("sell:type-data-18")}</p>
              <div>
                <FormControl
                  inputType="textfield"
                  type="text"
                  placeholder={t("sell:section-18-placeholder")}
                  value={targaCode}
                  handleChange={(e) => setTargaCode(e.target.value)}
                />
                <span
                  className="question-mark-18"
                  onClick={() => openModal("sec18")}
                >
                  ?
                </span>
              </div>
            </div>
            <span>{t("common:or")}</span>
            <div className="sec-24">
              <p>{t("sell:type-data-24")}</p>
              <div>
                <FormControl
                  inputType="textfield"
                  type="text"
                  placeholder={t("sell:section-24-placeholder")}
                  value={general_data?.targaCode24 || ""}
                  handleChange={(e) =>
                    dispatch(
                      setGeneralData({
                        ...general_data,
                        targaCode24: e.target.value,
                      })
                    )
                  }
                />
                <span
                  className="question-mark-24"
                  onClick={() => openModal("sec24")}
                >
                  ?
                </span>
              </div>
            </div>
          </div>
          {isHelpWindowOpen && (
            <HelpInfo content={modalContent} onClose={closeModal} />
          )}
          <div className="dates-section">
            <FormControl label={t("sell:date-of-construction")}>
              <Select
                label="month"
                data={months}
                handleChange={(event, newValue) =>
                  dispatch(
                    setRootAdData({
                      ...ad_data,
                      month: newValue ? newValue.month : "",
                    })
                  )
                }
                defaultValue={months.find(
                  (item) => item.month === ad_data?.month
                )}
              />
              <Select
                label="year"
                data={years}
                handleChange={(event, newValue) =>
                  dispatch(
                    setRootAdData({
                      ...ad_data,
                      year: newValue ? newValue.year : "",
                    })
                  )
                }
                defaultValue={years.find((item) => item.year === ad_data?.year)}
              />
              <Button
                disabled={!targaCode && !general_data.targaCode24}
                click={getTargaData}
                className="determine-btn"
                label={t("sell:determine-vehicle")}
                color="red"
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h4>{t("sell:without-registration-card")}</h4>
          <h5 className="italic">{t("sell:without-the-information")}</h5>
        </div>
        <Button
          click={continueWithoutRegistration}
          className="without-regdata-btn"
          color="transparent"
          label={t("sell:continue-without")}
        />
      </div>
    </div>
  );
};
const stateToProps = (state) => {
  const { car_model, ad_data, general_data } = state.car;
  const { profileData } = state.profile;
  return { car_model, ad_data, general_data, profileData };
};
export default connect(stateToProps)(IdentifyVehicle);
