import React from "react";
import { useTranslation } from "react-i18next";

import "./index.css";

const Impressum = () => {
  const { t } = useTranslation(["footer"]);
  return (
    <div className="impressum">
      <header>
        <h3>{t("footer:impressum-header")}</h3>
      </header>
      <main>
        <p>
          {t("footer:impressum-main-1-1")}
          <br />
          {t("footer:impressum-main-1-2")}
        </p>
        <div className="section-2">
          <p>{t("footer:impressum-main-2-heading")}</p>
          <div>
            <span>
              <p>CARINDEX AG</p>
              <p>Arnold Baumgartner-Str. 9</p>
              <p>CH-2540 Grenchen</p>
            </span>
            <span>
              <span>
                <p>Telefon</p>
                <p>E-Mail:</p>
                <p>Web:</p>
              </span>
              <span>
                <p>+41 (0)58 689 65 05</p>
                <p>info@carindex.ch</p>
                <p>www.carindex.ch</p>
              </span>
            </span>
            <span>
              <span>
                <p>CEO:</p>
                <p>UID:</p>
              </span>
              <span>
                <p>Luca Prota</p>
                <p>CHE-170.585.380</p>
              </span>
            </span>
          </div>
        </div>
        <div className="section-3">
          <p>
            {t("footer:impressum-main-3-1")}
            <span>{t("footer:impressum-main-3-contacnt-form")}</span>
            {t("footer:impressum-main-3-1-2")}
            <span>"info@carindex.ch"</span>
          </p>
          <p>{t("footer:impressum-main-3-2")}</p>
          <p>
            {"\u2022 "}
            {t("footer:impressum-main-3-3")}
          </p>
        </div>
      </main>
    </div>
  );
};

export default Impressum;
