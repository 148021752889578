import React from "react";
import { useTranslation } from "react-i18next";

export const getMonthsData = () => {
  const { t, i18n } = useTranslation(["common", "sell"]);
  return [
    { name: t("common:january"), month: 1 },
    { name: t("common:february"), month: 2 },
    { name: t("common:march"), month: 3 },
    { name: t("common:april"), month: 4 },
    { name: t("common:may"), month: 5 },
    { name: t("common:june"), month: 6 },
    { name: t("common:july"), month: 7 },
    { name: t("common:august"), month: 8 },
    { name: t("common:september"), month: 9 },
    { name: t("common:october"), month: 10 },
    { name: t("common:november"), month: 11 },
    { name: t("common:december"), month: 12 },
  ];
};

export const getNumericMonthsWithCancel = () => {
  const { t } = useTranslation("common");
  return [{ name: t("common:cancel"), month: "" }, ...numericMonths];
};

export const numericMonths = [
  { name: "1", month: 1 },
  { name: "2", month: 2 },
  { name: "3", month: 3 },
  { name: "4", month: 4 },
  { name: "5", month: 5 },
  { name: "6", month: 6 },
  { name: "7", month: 7 },
  { name: "8", month: 8 },
  { name: "9", month: 9 },
  { name: "10", month: 10 },
  { name: "11", month: 11 },
  { name: "12", month: 12 },
];

export const currentYear = new Date().getFullYear();
const firstYear = 1905;
const totalYears = currentYear - firstYear;

export const years = Array.from(new Array(totalYears), (val, index) => {
  const yearValue = currentYear - index;
  return { year: yearValue, name: yearValue.toString() };
});

export const getYearsWithCancel = () => {
  const { t } = useTranslation("common");
  return [
    { year: "", name: t("common:cancel") },
    ...Array.from(new Array(totalYears), (val, index) => {
      const yearValue = currentYear - index;
      return { year: yearValue, name: yearValue.toString() };
    }),
  ];
};

export const yearsWithAll = [{ year: -1, name: "All" }, ...years];

export const ADS_PER_PAGE = 5;

export const MYCARS = {
  ACTIVE: { ID: 1, LABEL: "mycars" },
  DRAFTS: { ID: 2, LABEL: "drafts" },
};

export const defaultLocation = {
  lat: 47.378308,
  lng: 8.530403,
};

export const previousOwnerCount = [
  { name: "0", value: 0 },
  { name: "1", value: 1 },
  { name: "2", value: 2 },
  { name: "3", value: 3 },
  { name: "4", value: 4 },
  { name: "5", value: 5 },
  { name: "6", value: 6 },
  { name: "7", value: 7 },
  { name: "8", value: 8 },
  { name: "9", value: 9 },
  { name: "10", value: 10 },
];

export const getRegionsSwiss = () => [
  { name: "AG" },
  { name: "AI" },
  { name: "AR" },
  { name: "BE" },
  { name: "BL" },
  { name: "BS" },
  { name: "FR" },
  { name: "GE" },
  { name: "GL" },
  { name: "GR" },
  { name: "JU" },
  { name: "LU" },
  { name: "NE" },
  { name: "NW" },
  { name: "OW" },
  { name: "SG" },
  { name: "SH" },
  { name: "SO" },
  { name: "SZ" },
  { name: "TG" },
  { name: "TI" },
  { name: "UR" },
  { name: "VD" },
  { name: "VS" },
  { name: "ZG" },
  { name: "ZH" },
];

export const getEuroNorm = () => {
  return [
    { ideuronorm: 1, name: "Euro 1" },
    { ideuronorm: 2, name: "Euro 2" },
    { ideuronorm: 3, name: "Euro 3" },
    { ideuronorm: 4, name: "Euro 4" },
    { ideuronorm: 5, name: "Euro 5" },
    { ideuronorm: 6, name: "Euro 6" },
    { ideuronorm: 7, name: "Euro 6a" },
    { ideuronorm: 8, name: "Euro 6b" },
    { ideuronorm: 9, name: "Euro 6c" },
    { ideuronorm: 10, name: "Euro 6d" },
    { ideuronorm: 11, name: "Euro 6d Temp" },
  ];
};

export const getEuroNormWithAll = () => {
  const { t, i18n } = useTranslation(["common", "sell"]);
  return [{ ideuronorm: "", name: t("common:all") }, ...getEuroNorm()];
};

// export const getDriveType = () => {
//   const { t, i18n } = useTranslation(["sell"]);
//   return [
//     { iddrivetype: 1, name: `${t("sell:front-wheel")} (FWD)` },
//     { iddrivetype: 2, name: `${t("sell:rear-wheel")} (RWD)` },
//     { iddrivetype: 3, name: `${t("sell:all-wheel")} (AWD)` },
//     { iddrivetype: 4, name: t("sell:rear-wheel-awd") },
//     { iddrivetype: 5, name: t("sell:electric-drive") },
//     { iddrivetype: 6, name: t("sell:hybrid-drive") },
//   ];
// };

// export const getDriveTypeWithAll = () => {
//   const { t, i18n } = useTranslation(["common", "sell"]);
//   return [{ iddrivetype: "", name: t("common:all") }, ...getDriveType];
// };

export const getTrailerHitchWithAll = () => {
  const { t, i18n } = useTranslation(["common", "sell"]);
  return [
    { idtrailerhitch: "", name: t("common:all") },
    { idtrailerhitch: 1, name: t("sell:removable") },
    { idtrailerhitch: 2, name: t("sell:fix") },
    { idtrailerhitch: 3, name: t("sell:pivotable") },
  ];
};

export const getTrailerLoadWithAll = () => {
  const { t, i18n } = useTranslation(["common"]);
  return [
    { idtrailerload: "", name: t("common:all") },
    { idtrailerload: 1, name: "750 kg" },
    { idtrailerload: 2, name: "1'000 kg" },
    { idtrailerload: 3, name: "1'100 kg" },
    { idtrailerload: 4, name: "1'200 kg" },
    { idtrailerload: 5, name: "1'300 kg" },
    { idtrailerload: 6, name: "1'400 kg" },
    { idtrailerload: 7, name: "1'500 kg" },
    { idtrailerload: 8, name: "1'600 kg" },
    { idtrailerload: 9, name: "1'700 kg" },
    { idtrailerload: 10, name: "1'800 kg" },
    { idtrailerload: 11, name: "1'900 kg" },
    { idtrailerload: 12, name: "2'000 kg" },
    { idtrailerload: 13, name: "2'500 kg" },
    { idtrailerload: 14, name: "3'000 kg" },
    { idtrailerload: 15, name: "3'500 kg" },
  ];
};

export const getEquipment = () => [
  { id: 1, label: "360-camera", checked: false },
  { id: 2, label: "4WD", checked: false },
  { id: 3, label: "abs", checked: false },
  { id: 4, label: "adaptive-cruise-control", checked: false },
  { id: 5, label: "adaptive-headlights", checked: false },
  { id: 6, label: "air-conditioning", checked: false },
  { id: 7, label: "air-suspension", checked: false },
  { id: 8, label: "alarm-system", checked: false },
  { id: 9, label: "alloy-wheels", checked: false },
  { id: 10, label: "ambient-lightning", checked: false },
  { id: 11, label: "android-auto", checked: false },
  { id: 12, label: "apple-carplay", checked: false },
  { id: 13, label: "armrest", checked: false },
  { id: 14, label: "automatic-climate-control", checked: false },
  {
    id: 15,
    label: "automatic-climate-control-2-zones",
    checked: false,
  },
  {
    id: 16,
    label: "automatic-climate-control-3-zones",
    checked: false,
  },
  {
    id: 17,
    label: "automatic-climate-control-4-zones",
    checked: false,
  },
  { id: 18, label: "auxilary-heating", checked: false },
  { id: 19, label: "awning", checked: false },
  { id: 20, label: "bi-xenon-headlights", checked: false },
  { id: 21, label: "biodisel-conversion", checked: false },
  { id: 22, label: "bluetooth", checked: false },
  { id: 23, label: "blind-spot-monitor", checked: false },
  { id: 24, label: "cargo-barrier", checked: false },
  { id: 25, label: "cab-or-rented-car", checked: false },
  { id: 26, label: "catalytic-converter", checked: false },
  { id: 27, label: "cd-player", checked: false },
  { id: 28, label: "central-door-lock", checked: false },
  { id: 29, label: "cruise-control", checked: false },
  { id: 30, label: "daytime-running-lights", checked: false },
  { id: 31, label: "digital-cockpit", checked: false },
  { id: 32, label: "digital-radio", checked: false },
  { id: 33, label: "disabled-accessible", checked: false },
  { id: 34, label: "distance-warning-system", checked: false },
  {
    id: 35,
    label: "driver-drownsiness-detection",
    checked: false,
  },
  { id: 36, label: "driver-side-airbag", checked: false },
  { id: 37, label: "e10-enabled", checked: false },
  {
    id: 38,
    label: "electric-backseat-adjustment",
    checked: false,
  },
  { id: 39, label: "electric-tailgate", checked: false },
  { id: 40, label: "electrical-side-mirrors", checked: false },
  {
    id: 41,
    label: "electrically-adjustable-seats",
    checked: false,
  },
  {
    id: 42,
    label: "electrically-heated-windshield",
    checked: false,
  },
  {
    id: 43,
    label: "electronic-stability-control",
    checked: false,
  },
  { id: 44, label: "emergency-brake-assistant", checked: false },
  { id: 45, label: "emergency-system", checked: false },
  { id: 46, label: "emergency-tyre", checked: false },
  { id: 47, label: "emergency-tyre-reoaur-kit", checked: false },
  { id: 48, label: "fog-lights", checked: false },
  { id: 49, label: "fold-flats-passenger-seat", checked: false },
  { id: 50, label: "full-led-headlight", checked: false },
  {
    id: 51,
    label: "glare-free-high-beam-headlights",
    checked: false,
  },
  { id: 52, label: "hands-free-equipment", checked: false },
  { id: 53, label: "head-airbag", checked: false },
  { id: 54, label: "headlight-washer-system", checked: false },
  { id: 55, label: "heads-up-display", checked: false },
  { id: 56, label: "heated-steering-wheel", checked: false },
  { id: 57, label: "high-beam-assist", checked: false },
  { id: 58, label: "hill-holder", checked: false },
  { id: 59, label: "immobilizer", checked: false },
  {
    id: 60,
    label: "induction-charging-for-smartphones",
    checked: false,
  },
  {
    id: 61,
    label: "integrated-music-streaming",
    checked: false,
  },
  { id: 62, label: "isofix", checked: false },
  { id: 63, label: "keyless-central-door-lock", checked: false },
  {
    id: 64,
    label: "lane-departure-warning-system",
    checked: false,
  },
  { id: 65, label: "laser-headlights", checked: false },
  { id: 66, label: "leather-steering-wheel", checked: false },
  {
    id: 67,
    label: "led-daytime-running-lights",
    checked: false,
  },
  { id: 68, label: "led-headlights", checked: false },
  { id: 69, label: "light-sensor", checked: false },
  { id: 70, label: "lumbar-support", checked: false },
  { id: 71, label: "massage-seats", checked: false },
  { id: 72, label: "mp3", checked: false },
  {
    id: 73,
    label: "multi-function-steering-wheel",
    checked: false,
  },
  { id: 74, label: "navigation-system", checked: false },
  { id: 75, label: "night-view-assist", checked: false },
  { id: 76, label: "on-board-computer", checked: false },
  { id: 77, label: "panorama-roof", checked: false },
  {
    id: 78,
    label: "parking-assist-system-camera",
    checked: false,
  },
  {
    id: 79,
    label: "parking-assist-system-self-steering",
    checked: false,
  },
  {
    id: 80,
    label: "parking-assist-system-sensors-front",
    checked: false,
  },
  {
    id: 81,
    label: "parking-assist-system-sensors-rear",
    checked: false,
  },
  { id: 82, label: "particulate-filter", checked: false },
  { id: 83, label: "passenger-side-airbag", checked: false },
  { id: 84, label: "power-steering", checked: false },
  { id: 85, label: "power-windows", checked: false },
  { id: 86, label: "radio", checked: false },
  { id: 87, label: "rain-sensor", checked: false },
  { id: 88, label: "range-extender", checked: false },
  { id: 89, label: "rear-airbag", checked: false },
  { id: 90, label: "right-hand-drive", checked: false },
  { id: 91, label: "roof-rack", checked: false },
  { id: 92, label: "seat-heating", checked: false },
  { id: 93, label: "seat-ventilation", checked: false },
  { id: 94, label: "shift-paddles", checked: false },
  { id: 95, label: "side-airbag", checked: false },
  { id: 96, label: "ski-bag", checked: false },
  { id: 97, label: "sliding-door", checked: false },
  { id: 98, label: "sliding-door-left", checked: false },
  { id: 99, label: "sliding-door-right", checked: false },
  { id: 100, label: "smokers-package", checked: false },
  { id: 101, label: "sound-system", checked: false },
  { id: 102, label: "spare-tyre", checked: false },
  {
    id: 103,
    label: "speed-limit-control-system",
    checked: false,
  },
  { id: 104, label: "split-rear-seats", checked: false },
  { id: 105, label: "spoiler", checked: false },
  { id: 106, label: "sport-package", checked: false },
  { id: 107, label: "sport-seats", checked: false },
  { id: 108, label: "start-stop-system", checked: false },
  { id: 109, label: "steel-wheels", checked: false },
  { id: 110, label: "summer-tyres", checked: false },
  { id: 111, label: "sunroof", checked: false },
  { id: 112, label: "television", checked: false },
  { id: 113, label: "tinted-windows", checked: false },
  { id: 114, label: "touch-screen", checked: false },
  { id: 115, label: "traffic-sing-recognition", checked: false },
  { id: 116, label: "trailer-hitch", checked: false },
  { id: 117, label: "tuned-car", checked: false },
  { id: 118, label: "usb", checked: false },
  { id: 119, label: "voice-control", checked: false },
  { id: 120, label: "wind-deflector", checked: false },
  { id: 121, label: "winter-package", checked: false },
  { id: 122, label: "winter-tyres", checked: false },
  { id: 123, label: "wlan-wifi-hotspot", checked: false },
  { id: 124, label: "xenon-headlights", checked: false },
];
