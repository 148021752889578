import React, { useEffect } from "react";
import history from "../../lib/history";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  findAds2,
  resetCarModel,
  resetFindProps,
  getCarModel,
  setFindProps,
  customSetFindProps,
} from "../../store/actions";
import { setSearchProps, numberWithCommas } from "../../lib/utils";
import { yearsWithAll } from "../../data";
import TextField from "../../components/text-field/index";
import Select from "../../components/select/index";
import Button from "../../components/button/index";
import FormControl from "../../components/form-control";
import "./index.css";
import { ArrowRight } from "../../components/svg-icons";
import CarsOfDay from "./cars-of-day";
import vw from "../../assets/images/brands/vw.png";
import audi from "../../assets/images/brands/audi.png";
import bmw from "../../assets/images/brands/bmw.png";
import fiat from "../../assets/images/brands/fiat.png";
import ford from "../../assets/images/brands/ford.png";
import mercedes from "../../assets/images/brands/mercedes.png";
import opel from "../../assets/images/brands/opel.png";
import renault from "../../assets/images/brands/renault.png";
import NewsTestReports from "../home/news-testreports/index";
import CarouselHome from "./carousel-home/index";
import { useCarsOfTheDay } from "../../hooks";

const Home = (props) => {
  const { t } = useTranslation(["home", "common"]);
  const { dispatch, makeWithAll, carModelWithAll, find_data, ads_total } =
    props;
  const carsOfTheDay = useCarsOfTheDay();

  const popBrands = [
    { label: "BMW", src: bmw, idmake: 10 },
    { label: "Volkswagen", src: vw, idmake: 109 },
    { label: "Audi", src: audi, idmake: 8 },
    { label: "Mercedes-Benz", src: mercedes, idmake: 60 },
    { label: "Ford", src: ford, idmake: 262 },
    { label: "Opel", src: opel, idmake: 103 },
    { label: "Fiat", src: fiat, idmake: 28 },
    { label: "Renault", src: renault, idmake: 72 },
  ];

  const goToResultsPage = () => {
    history.push("/results");
  };

  const goToAdvancedSearch = () => {
    history.push("advanced-search");
  };

  useEffect(() => {
    dispatch(resetFindProps());
    dispatch(resetCarModel());
    dispatch(findAds2());
    dispatch(getCarModel(0, 0));
    dispatch(customSetFindProps("idBodyType", [null]));
  }, []);

  return (
    <div className="flex-row-wrapper home-page">
      <div className="full-width relative home-image-container">
        <CarouselHome />
      </div>
      <div className="flex-row-wrapper home-heading">
        <h3 className="secondary-font-color home-title no-margin font-weight-normal">
          {t("look-among")}
          <span className="primary-color">
            {" "}
            {ads_total ? numberWithCommas(ads_total) : "0"}{" "}
          </span>
          {t("vehicles")}
        </h3>
        <h3 className="secondary-font-color home-subtitle no-margin font-weight-normal">
          {t("home-subheading")}
        </h3>
      </div>
      <div className="home-input-container">
        <div className="home-input-grid">
          <FormControl
            inputType="select"
            placeholder={t("home:car-brand")}
            handleChange={(_, newValue) => {
              const value = newValue ? [newValue.idmake] : null;
              dispatch(setFindProps("idModel", null));
              dispatch(setFindProps("idMake", value));
              dispatch(getCarModel(value[0], 0));
            }}
            data={makeWithAll}
            value={makeWithAll.find(
              (item) =>
                item.idmake === (find_data.idMake ? find_data.idMake[0] : null)
            )}
          />
          <FormControl
            inputType="select"
            placeholder={t("home:model")}
            handleChange={(_, newValue) => {
              setSearchProps(
                dispatch,
                "idModel",
                newValue ? [newValue.idmodel] : null
              );
            }}
            data={Array.isArray(carModelWithAll[0]) ? carModelWithAll[0] : []}
            value={(Array.isArray(carModelWithAll[0])
              ? carModelWithAll[0]
              : []
            ).find(
              (item) =>
                item.idmodel ===
                (find_data.idModel ? find_data.idModel[0] : null)
            )}
            disabled={
              (Array.isArray(carModelWithAll[0]) ? carModelWithAll[0] : [])
                .length === 0
            }
          />
          <Select
            placeholder={t("home:year-from")}
            handleChange={(event, newValue) => {
              setSearchProps(
                dispatch,
                "yearFrom",
                newValue ? newValue.year : null
              );
            }}
            label="yearFrom"
            data={yearsWithAll}
            defaultValue={yearsWithAll.find(
              (item) => item.year === find_data.yearFrom
            )}
          />
          <TextField
            id="price"
            type="number"
            placeholder={t("home:price-until")}
            value={find_data.priceTo ? find_data.priceTo : ""}
            changeHandler={(event) => {
              setSearchProps(dispatch, "priceTo", parseInt(event.target.value));
            }}
          />
          <Button
            click={goToResultsPage}
            className="search-button"
            label={
              (ads_total ? numberWithCommas(ads_total) : "0") +
              " " +
              t("common:search")
            }
            color="red"
          />
        </div>
        <Button
          color="transparent"
          className="goToAdvancedSearchBtn"
          click={goToAdvancedSearch}
        >
          <span>{t("common:advanced-search")}</span>
          <ArrowRight />
        </Button>
      </div>
      <div className="cars-of-the-day">
        <p>{t("home:cars-of-the-day")}</p>
        <CarsOfDay cars={carsOfTheDay} />
      </div>
      <div className="most-searched-brands">
        <p>{t("home:most-searched-brands")}</p>
        <div className="brands-container">
          {popBrands.map((item, index) => {
            return (
              <div className="brand-wrapper" key={index}>
                <span className="brand-logo">
                  <img src={item.src} />
                </span>
                <p>{item.label}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="news-testreports-revue">
        <p>{t("home:news-testreports-revue")}</p>
        <NewsTestReports />
      </div>
    </div>
  );
};

const stateToProps = (state) => {
  const { makeWithAll, carModelWithAll, find_data, ads_total } = state.car;
  return {
    makeWithAll,
    carModelWithAll,
    find_data,
    ads_total,
  };
};

export default connect(stateToProps)(Home);
