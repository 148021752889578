import React from "react";
import { ArrowLeft, ArrowRight } from "../svg-icons/index";
import "./index.css";

const Button = (props) => {
  const { className, label, click, color, disabled, id, type, style } = props;

  return (
    <button
      className={`button__ ${color} ${className ? className : ""}`}
      onClick={click}
      disabled={disabled}
      id={id}
      type={type}
      style={style}
    >
      {label}
      {props.children ?? props.children}
    </button>
  );
};

export const IteratorButton = (props) => {
  const { label, direction, className, disabled, handler } = props;
  return (
    <button
      className={`iterator-button ${
        direction === "next" ? "previousButton" : "nextButton"
      } ${className}`}
      disabled={disabled}
      onClick={handler}
    >
      <span className={`${disabled && "label-disabled"}`}>{label}</span>
      {direction === "next" ? (
        <ArrowRight disabled={disabled} />
      ) : (
        <ArrowLeft disabled={disabled} />
      )}
    </button>
  );
};

export default Button;
