import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { USER_TYPES } from "../../types";
import "./index.css";

const ShownContactInfo = (props) => {
  const { profileData, chooseType } = props;
  const { t } = useTranslation(["navbar, common"]);

  if (chooseType === USER_TYPES.MERCHANT)
    return (
      <div className="showncontactinfo">
        <span>
          <p>{t("profile:info-shown-online")}</p>
          <div className="small-avatar">{props.children}</div>
          {profileData?.name ? (
            <p>{profileData?.name}</p>
          ) : (
            <p className="default-label">{t("profile:your-name")}</p>
          )}
        </span>
        <span>
          {profileData?.email ? (
            <p>{profileData?.email}</p>
          ) : (
            <p className="default-label">{t("profile:your-email")}</p>
          )}
          {profileData?.phones && profileData?.phones[0]?.number ? (
            <p>{profileData?.phones[0]?.number}</p>
          ) : (
            <p className="default-label">{t("profile:your-phone")}</p>
          )}
          {profileData?.address_data?.streetandnumber && (
            <p>{profileData?.address_data?.streetandnumber},</p>
          )}
          {profileData?.address_data?.zipcode ||
          profileData?.address_data?.city ? (
            <p>{`${
              profileData?.address_data?.zipcode
                ? profileData?.address_data?.zipcode
                : ""
            } ${profileData?.city ? profileData?.city : ""}`}</p>
          ) : (
            <p className="default-label">{t("profile:address-location")}</p>
          )}
        </span>
      </div>
    );
  if (chooseType === USER_TYPES.STANDARD)
    return (
      <div className="showncontactinfo">
        <span>
          <p>{t("profile:info-shown-online")}</p>
          <div className="small-avatar">{props.children}</div>
          {profileData?.display_name ? (
            <p>{profileData?.display_name}</p>
          ) : (
            <p className="default-label">{t("profile:your-name")}</p>
          )}
        </span>
        <span>
          {profileData?.email ? (
            <p>{profileData?.email}</p>
          ) : (
            <p className="default-label">{t("profile:your-email")}</p>
          )}
          {profileData?.contact_phone ? (
            <p>{profileData?.contact_phone}</p>
          ) : (
            <p className="default-label">{t("profile:your-phone")}</p>
          )}
          {profileData?.address ? (
            <p>{profileData?.address}</p>
          ) : (
            <p className="default-label">{t("profile:address")}</p>
          )}
        </span>
      </div>
    );
};

const stateToProps = (state) => {
  const { profileData, chooseType } = state.profile;
  return {
    profileData,
    chooseType,
  };
};

export default connect(stateToProps)(ShownContactInfo);
