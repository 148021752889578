import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import image0 from "../../../assets/images/carousel-home/image0.png";
import image1 from "../../../assets/images/carousel-home/image1.png";
import image2 from "../../../assets/images/carousel-home/image2.png";
import image3 from "../../../assets/images/carousel-home/image3.png";
import image4 from "../../../assets/images/carousel-home/image4.png";
import image5 from "../../../assets/images/carousel-home/image5.png";
import image6 from "../../../assets/images/carousel-home/image6.png";
import image7 from "../../../assets/images/carousel-home/image7.png";
import image8 from "../../../assets/images/carousel-home/image8.png";
import image9 from "../../../assets/images/carousel-home/image9.png";
import image10 from "../../../assets/images/carousel-home/image10.png";
import image11 from "../../../assets/images/carousel-home/image11.png";
import image12 from "../../../assets/images/carousel-home/image12.png";
import image13 from "../../../assets/images/carousel-home/image13.png";
import image14 from "../../../assets/images/carousel-home/image14.png";
import image15 from "../../../assets/images/carousel-home/image15.png";
import image16 from "../../../assets/images/carousel-home/image16.png";
import image17 from "../../../assets/images/carousel-home/image17.png";
import image18 from "../../../assets/images/carousel-home/image18.png";
import image19 from "../../../assets/images/carousel-home/image19.png";
import image20 from "../../../assets/images/carousel-home/image20.png";

import "swiper/css";
import "swiper/css/pagination";
import "./index.css";

function CarouselHome() {
  const { t } = useTranslation(["home"]);
  const sliders = [
    { img: image0 },
    { img: image1 },
    { img: image2 },
    { img: image3 },
    { img: image4 },
    { img: image5 },
    { img: image6 },
    { img: image7 },
    { img: image8 },
    { img: image9 },
    { img: image10 },
    { img: image11 },
    { img: image12 },
    { img: image13 },
    { img: image14 },
    { img: image15 },
    { img: image16 },
    { img: image17 },
    { img: image18 },
    { img: image19 },
    { img: image20 },
  ];

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
        pagination={{
          dinamicBullets: true,
          dynamicMainBullets: 4,
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination]}
      >
        {sliders.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <img src={item.img} />
            </SwiperSlide>
          );
        })}
        <div className="home-header-text">
          <h4 className="home-banner ">{t("home:home-banner")}</h4>
        </div>
      </Swiper>
    </>
  );
}

export default CarouselHome;
