export const getTermsConditionAccordionData = () => [
  { id: 1, subtitle: "terms-accordion-1", label: "terms-accordion-1-1" },
  { id: 2, subtitle: "terms-accordion-2", label: "terms-accordion-2-1" },
  { id: 3, subtitle: "terms-accordion-3", label: "terms-accordion-3-1" },
  { id: 4, subtitle: "terms-accordion-4", label: "terms-accordion-4-1" },
  { id: 5, subtitle: "terms-accordion-5", label: "terms-accordion-5-1" },
  { id: 6, subtitle: "terms-accordion-6", label: "terms-accordion-6-1" },
  { id: 7, subtitle: "terms-accordion-7", label: "terms-accordion-7-1" },
  {
    id: 8,
    subtitle: "terms-accordion-8",
    label: [{ text: "terms-accordion-8-1" }, { text: "terms-accordion-8-2" }],
  },
  { id: 9, subtitle: "terms-accordion-9", label: "terms-accordion-9-1" },
  {
    id: 10,
    subtitle: "terms-accordion-10",
    label: [
      { text: "terms-accordion-10-1" },
      { emptyRow: true },
      { text: "terms-accordion-10-2", bulleted: true },
      { text: "terms-accordion-10-3", bulleted: true },
      { text: "terms-accordion-10-4", bulleted: true },
      { text: "terms-accordion-10-5", bulleted: true },
      { text: "terms-accordion-10-6", bulleted: true },
      { emptyRow: true },
      { text: "terms-accordion-10-7" },
      { text: "terms-accordion-10-8" },
    ],
  },
  { id: 11, subtitle: "terms-accordion-11", label: "terms-accordion-11-1" },
  {
    id: 12,
    subtitle: "terms-accordion-12",
    label: [{ text: "terms-accordion-12-1" }, { text: "terms-accordion-12-2" }],
  },
  {
    id: 13,
    subtitle: "terms-accordion-13",
    label: [{ text: "terms-accordion-13-1" }, { text: "terms-accordion-13-2" }],
  },
  { id: 14, subtitle: "terms-accordion-14", label: "terms-accordion-14-1" },
  { id: 15, subtitle: "terms-accordion-15", label: "terms-accordion-15-1" },
  { id: 16, subtitle: "terms-accordion-16", label: "terms-accordion-16-1" },
  { id: 17, subtitle: "terms-accordion-17", label: "terms-accordion-17-1" },
  { id: 18, subtitle: "terms-accordion-18", label: "terms-accordion-18-1" },
  { id: 19, subtitle: "terms-accordion-19", label: "terms-accordion-19-1" },
];

export const getInsertionRulesaccordionData = () => [
  {
    id: 1,
    subtitle: "insertion-rules-1",
    label: [
      { text: "insertion-rules-1-1" },
      { emptyRow: true },
      { text: "insertion-rules-1-2" },
      { emptyRow: true },
      { text: "insertion-rules-1-3" },
      { emptyRow: true },
      { text: "insertion-rules-1-4" },
      { emptyRow: true },
      { text: "insertion-rules-1-5" },
      { emptyRow: true },
      { text: "insertion-rules-1-6" },
      { emptyRow: true },
      { text: "insertion-rules-1-7" },
      { emptyRow: true },
      { text: "insertion-rules-1-8" },
      { emptyRow: true },
      { text: "insertion-rules-1-9" },
      { emptyRow: true },
      { text: "insertion-rules-1-10" },
    ],
  },
  {
    id: 2,
    subtitle: "insertion-rules-2",
    label: [
      { text: "insertion-rules-2-1", bulleted: true },
      { text: "insertion-rules-2-2", bulleted: true },
      { text: "insertion-rules-2-3", bulleted: true },
    ],
  },
  {
    id: 3,
    subtitle: "insertion-rules-3",
    label: [
      { text: "insertion-rules-2-1", bulleted: true },
      { text: "insertion-rules-3-2", bulleted: true },
    ],
  },
  { id: 4, subtitle: "insertion-rules-4", label: "insertion-rules-4-1" },
  { id: 5, subtitle: "insertion-rules-5", label: "insertion-rules-5-1" },
  { id: 6, subtitle: "insertion-rules-6", label: "insertion-rules-6-1" },
  { id: 7, subtitle: "insertion-rules-7", label: "insertion-rules-7-1" },
  { id: 8, subtitle: "insertion-rules-8", label: "insertion-rules-8-1" },
  { id: 9, subtitle: "insertion-rules-9", label: "insertion-rules-9-1" },
];

// for data_protection page
export const getAccordionData = () => [
  { id: 1, subtitle: "data-protection-1", label: "data-protection-1-1" },
  { id: 2, subtitle: "data-protection-2", label: "data-protection-2-1" },
  {
    id: 3,
    subtitle: "data-protection-3",
    label: [
      { text: "data-protection-3-1" },
      { text: "data-protection-3-2", bulleted: true },
      { text: "data-protection-3-3", bulleted: true },
      { text: "data-protection-3-4", bulleted: true },
      { text: "data-protection-3-5", bulleted: true },
      { text: "data-protection-3-6", bulleted: true },
      { text: "data-protection-3-7", bulleted: true },
      { text: "data-protection-3-8", bulleted: true },
      { text: "data-protection-3-9", bulleted: true },
      { text: "data-protection-3-10" },
    ],
    subAccordions: [
      {
        id: 1,
        subtitle: "sub-accordion3-1",
        label: [
          { text: "sub-accordion3-1-1" },
          { text: "sub-accordion3-1-2" },
          { text: "sub-accordion3-1-3", bulleted: true },
          { text: "sub-accordion3-1-4", bulleted: true },
          { text: "sub-accordion3-1-5" },
        ],
      },
      {
        id: 2,
        subtitle: "sub-accordion3-2",
        label: [
          { text: "sub-accordion3-2-1" },
          { text: "sub-accordion3-2-2", bulleted: true },
          { text: "sub-accordion3-2-3", bulleted: true },
          { text: "sub-accordion3-2-4", bulleted: true },
          { text: "sub-accordion3-2-5", bulleted: true },
          { text: "sub-accordion3-2-6", bulleted: true },
          { text: "sub-accordion3-2-7", bulleted: true },
          { text: "sub-accordion3-2-8", bulleted: true },
          { text: "sub-accordion3-2-9", bulleted: true },
          { text: "sub-accordion3-2-10", bulleted: true },
          { text: "sub-accordion3-2-11" },
        ],
      },
      { id: 3, subtitle: "sub-accordion3-3", label: "sub-accordion3-3-1" },
      {
        id: 4,
        subtitle: "sub-accordion3-4",
        label: [
          { text: "sub-accordion3-4-1" },
          { text: "sub-accordion3-4-2" },
          { text: "sub-accordion3-4-3" },
          { link: "https://support.apple.com/de-ch/HT207092" },
          { text: "sub-accordion3-4-4" },
          {
            link: "https://support.google.com/accounts/answer/3467281?hl=de",
          },
        ],
      },
      {
        id: 5,
        subtitle: "sub-accordion3-5",
        label: [
          { text: "sub-accordion3-5-1" },
          { text: "sub-accordion3-5-2" },
          { text: "sub-accordion3-5-3" },
        ],
      },
      {
        id: 6,
        subtitle: "sub-accordion3-6",
        label: [
          { text: "sub-accordion3-6-1" },
          { text: "sub-accordion3-6-2" },
          { text: "sub-accordion3-6-3" },
        ],
      },
      { id: 7, subtitle: "sub-accordion3-7", label: "sub-accordion3-7-1" },
      { id: 8, subtitle: "sub-accordion3-8", label: "sub-accordion3-8-1" },
      {
        id: 9,
        subtitle: "sub-accordion3-9",
        label: [
          { text: "sub-accordion3-9-1" },
          { text: "sub-accordion3-9-2" },
          { text: "sub-accordion3-9-3" },
          { text: "sub-accordion3-9-4" },
        ],
      },
      { id: 10, subtitle: "sub-accordion3-10", label: "sub-accordion3-10-1" },
    ],
  },
  { id: 4, subtitle: "data-protection-4", label: "data-protection-4-1" },
  {
    id: 5,
    subtitle: "data-protection-5",
    label: [{ text: "data-protection-5-1" }, { text: "data-protection-5-2" }],
    subAccordions: [
      {
        id: 1,
        subtitle: "sub-accordion5-1",
        label: [
          { text: "sub-accordion5-1-1" },
          { text: "sub-accordion5-1-2" },
          { text: "sub-accordion5-1-3" },
        ],
      },
      {
        id: 2,
        subtitle: "sub-accordion5-2",
        label: "sub-accordion5-2-1",
      },
      {
        id: 3,
        subtitle: "sub-accordion5-3",
        label: [
          { text: "sub-accordion5-3-1" },
          { text: "sub-accordion5-3-2" },
          { text: "sub-accordion5-3-3" },
          { text: "sub-accordion5-3-4" },
          { text: "sub-accordion5-3-5" },
        ],
      },
    ],
  },
  {
    id: 6,
    subtitle: "data-protection-6",
    subAccordions: [
      {
        id: 1,
        subtitle: "sub-accordion6-1",
        label: [
          { text: "sub-accordion6-1-1" },
          { text: "sub-accordion6-1-2" },
          { text: "sub-accordion6-1-3" },
          { text: "sub-accordion6-1-4" },
          { text: "sub-accordion6-1-5" },
        ],
      },
      {
        id: 2,
        subtitle: "sub-accordion6-2",
        label: [{ text: "sub-accordion6-2-1" }, { text: "sub-accordion6-2-2" }],
      },
      {
        id: 3,
        subtitle: "sub-accordion6-3",
        label: [{ text: "sub-accordion6-3-1" }, { text: "sub-accordion6-3-2" }],
      },
    ],
  },
  { id: 7, subtitle: "data-protection-7", label: "data-protection-7-1" },
  {
    id: 8,
    subtitle: "data-protection-8",
    label: [
      { text: "data-protection-8-1" },
      { text: "data-protection-8-2" },
      { text: "data-protection-8-3" },
      { text: "data-protection-8-4" },
    ],
  },
  {
    id: 9,
    subtitle: "data-protection-9",
    label: [
      { text: "data-protection-9-1" },
      { text: "data-protection-9-2" },
      { text: "data-protection-9-3" },
    ],
  },
  {
    id: 10,
    subtitle: "data-protection-10",
    label: [
      { text: "data-protection-10-1" },
      { text: "data-protection-10-2" },
      { text: "data-protection-10-3" },
      { text: "data-protection-10-4" },
      { text: "data-protection-10-5" },
      { text: "data-protection-10-6" },
      { text: "data-protection-10-7" },
      { text: "data-protection-10-8" },
      { text: "data-protection-10-9" },
      { text: "data-protection-10-10" },
      { text: "data-protection-10-11" },
      { text: "data-protection-10-12" },
      { text: "data-protection-10-13" },
      { text: "data-protection-10-14" },
      { text: "data-protection-10-15" },
      { text: "data-protection-10-16" },
      { text: "data-protection-10-17" },
      { text: "data-protection-10-18" },
      { text: "data-protection-10-19" },
      { text: "data-protection-10-20" },
    ],
  },
  {
    id: 11,
    subtitle: "data-protection-11",
    label: [
      { text: "data-protection-11-1" },
      { text: "data-protection-11-2" },
      { text: "CARINDEX AG" },
      { text: "Datenschutz" },
      { text: "Arnold Baumgartner-Str. 9" },
      { text: "2540 Grenchen" },
    ],
  },
  {
    id: 12,
    subtitle: "data-protection-12",
    label: [{ text: "data-protection-12-1" }, { text: "data-protection-12-2" }],
  },
];

//cookies-text section

export const getCookiesData = () => [
  {
    id: 1,
    subtitle: "cookies-text-1",
    label: [
      { text: "cookies-text-1-1" },
      { text: "cookies-text-1-2" },
      { text: "cookies-text-1-3" },
      { text: "cookies-text-1-4" },
      { text: "Atlassian Jira Servicedesk" },
      { link: "https://www.atlassian.com/legal/cookies" },
      { text: "Hotjar" },
      { link: "https://www.hotjar.com/legal/policies/do-not-track/" },
      { text: "Google Analytics" },
      { link: "http://tools.google.com/dlpage/gaoptout?ht=de" },
      { text: "Tealium" },
      { link: "https://www.carindex.com/de/content/tealium-optout" },
      { text: "Google Tag Manager" },
      { link: "https://tools.google.com/dlpage/gaoptout?hl=en" },
      { text: "cookies-text-1-5" },
      { text: "cookies-text-1-6" },
      { text: "cookies-text-1-4" },
      { text: "Google DoubleClick " },
      { link: "https://support.google.com/ads/answer/2662922?hl=en-GB#zippy=" },
      { text: "Facebook" },
      { link: "https://en-gb.facebook.com/policy/cookies/" },
      { text: "Google Adsense" },
      { link: "https://support.google.com/adsense/answer/1348695?hl=en-GB" },
      { text: "Google Optimize" },
      { link: "https://tools.google.com/dlpage/gaoptout?hl=en" },
      { text: "Microsoft Bing " },
      { link: "https://help.ads.microsoft.com/apex/index/3/en/53056/ " },
      { text: "Crossengage" },
      {
        link: "https://documentation.crossengage.io/tracking/user-behaviour/opt-out-from-tracking",
      },
    ],
  },
  {
    id: 2,
    subtitle: "cookies-text-2",
    label: [
      { text: "cookies-text-2-1" },
      { text: "cookies-text-2-2" },
      { text: "cookies-text-2-3" },
      { text: "cookies-text-2-4" },
      { text: "cookies-text-2-5" },
      { text: "cookies-text-2-6" },
    ],
  },
  {
    id: 3,
    subtitle: "cookies-text-3",
    label: [{ text: "cookies-text-3-1" }, { text: "cookies-text-3-2" }],
  },
  {
    id: 4,
    subtitle: "cookies-text-4",
    label: [
      { text: "cookies-text-4-1" },
      { link: "http://www.meine-cookies.org/" },
      { link: "http://www.youronlinechoices.com" },
      { link: "https://www.ghostery.com/" },
    ],
  },
];
