import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router";
import { connect, Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "./components/error-boundary";
import { getDefaultRoute } from "./lib/utils";
import { store } from ".";
import navConfig from "./.nav-config";
import NotFound from "./screens/not-found";
import ProtectedRoute from "./components/protected-route";
import Layout from "./components/layout/index";
import ScrollToTop from "./lib/scroll-to-top";
import ClearState from "./screens/clear-state";

const App = (props) => {
  const { category } = props;
  const mapRoutes = (routes, extraProps = {}) => {
    return routes
      .filter((route) => !!route.path && !!route.component)
      .map((item) => {
        const { path } = item;
        const ActualRoute = item.protected ? ProtectedRoute : Route;
        return (
          <ActualRoute
            exact={item.exact}
            path={path}
            key={"nav-key-" + path}
            route={item}
            // component={ item.component }
            render={(props) => {
              return item.render ? (
                item.render({ ...props, ...extraProps, route: item })
              ) : (
                <item.component {...props} {...extraProps} route={item} />
              );
            }}
          />
        );
      });
  };

  const defaultRoute = getDefaultRoute();
  const routeComponents = mapRoutes(navConfig.routes, { category });
  const authRouteComponents = mapRoutes(navConfig.authRoutes, { category });
  const additionalRouteComponents = mapRoutes(navConfig.additionalRoutes, {
    category,
  });
  const legalRouteComponents = mapRoutes(navConfig.legalRoutes, { category });
  const notInMenuRouteComponents = mapRoutes(navConfig.notInMenuRoutes, {
    category,
  });
  const { pathname } = useLocation();

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <ScrollToTop dependency={[pathname]} />
        <ClearState pathname={pathname} />
        <Layout className="mdc-drawer-app-content">
          <Switch>
            {routeComponents}
            {authRouteComponents}
            {additionalRouteComponents}
            {legalRouteComponents}
            {notInMenuRouteComponents}
            {defaultRoute ? (
              <Route
                exact
                path="/"
                render={() => <Redirect to={defaultRoute.path} />}
              />
            ) : null}
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Layout>
        <ToastContainer />
      </ErrorBoundary>
    </Provider>
  );
};

const stateToProps = (state) => {
  const { category } = state.common;
  return {
    category,
  };
};

export default connect(stateToProps)(App);
