import React from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ArrowDown } from "../../../components/svg-icons/index";
import AccordionSection from "./components/accordion-section";
import CookiesSection from "./components/cookies-section";
import { getAccordionData, getCookiesData } from "../data/data";

import "./index.css";

const accordionData = getAccordionData();
const cookiesData = getCookiesData();

const DataProtection = () => {
  const { t } = useTranslation(["footer"]);

  const headerDataProtection = [
    { id: 1, text: "data-protection-p-1" },
    { id: 2, text: "data-protection-p-2" },
    { id: 3, text: "data-protection-p-3" },
    { id: 4, text: "data-protection-p-4" },
    { id: 5, text: "data-protection-p-6" },
    { id: 6, text: "data-protection-p-5" },
  ];

  const headerCookies = [
    { id: 1, text: "cookies-p-1" },
    { id: 2, text: "cookies-p-2" },
  ];

  return (
    <div className="data-protection">
      <header>
        <h3>{t("footer:data-protection-header")}</h3>
      </header>
      <main>
        <div>
          {headerDataProtection.map((item) => (
            <p key={item.id}>{t(`footer:${item.text}`)}</p>
          ))}
        </div>
        {accordionData.map((section) => (
          <AccordionSection section={section} t={t} key={section.id} />
        ))}
      </main>
      <header>
        <h3>{t("footer:cookies-header")}</h3>
      </header>
      <main>
        <div>
          {headerCookies.map((item) => (
            <p key={item.id}>{t(`footer:${item.text}`)}</p>
          ))}
        </div>
        {cookiesData.map((section) => (
          <CookiesSection section={section} t={t} key={section.id} />
        ))}
      </main>
    </div>
  );
};

export default DataProtection;
