import React from "react";
import Logo from "../../assets/images/new-logo-white.svg";
import GooglePlay from "../../assets/images/google-play.svg";
import { NavLink } from "react-router-dom";
import AppStore from "../../assets/images/app-store.svg";
import { useTranslation } from "react-i18next";
import { currentYear } from "../../data";
import "./index.css";

const footer = () => {
  const { t, i18n } = useTranslation(["footer, common"]);
  const current_year = currentYear;

  return (
    <div className="footer flex-row-wrapper flex-justify-between footer-font-color">
      <div className="footer-top">
        <span className="flex-col-wrapper flex-aling-center footer-image-container">
          <img src={Logo} alt="" />
        </span>
      </div>
      <div className="footer-main-content">
        <div className="footer-main-subcontent">
          <div className="flex-col-wrapper">
            <div className="flex-row-wrapper footer-list-container">
              <h4 className="footer-header">{t("common:car-index")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink to="/advanced-search" className="list-item-text">
                    {t("common:advanced-search")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:find-garages")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:brand-overview")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:model-overview")}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="flex-row-wrapper footer-list-container">
              <h4 className="footer-header">{t("common:about-us")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("common:contact")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:newsletter")}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="flex-row-wrapper footer-list-container">
              <h4 className="footer-header">{t("footer:insertion")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:cars")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:products-and-prices")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:facts-and-figures")}
                  </NavLink>
                </li>
              </ul>
              <br></br>
              <h4 className="footer-header">{t("footer:advertising")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("common:car-index")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:automobil-revue")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:men")}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="flex-row-wrapper footer-list-container">
              <h4 className="footer-header">{t("footer:legal-security")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink
                    to="/legal/safety-instructions"
                    className="list-item-text"
                  >
                    {t("footer:safety-instructions")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/legal/insertion-rules"
                    className="list-item-text"
                  >
                    {t("footer:insertion-rules")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/legal/terms-and-conditions"
                    className="list-item-text"
                  >
                    {t("footer:terms-conditions")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/legal/impressum" className="list-item-text">
                    {t("footer:impressum")}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/legal/data-protection"
                    className="list-item-text"
                  >
                    {t("footer:data-protection-declaration")} <br />
                    {t("common:of")} {"CARINDEX AG"}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="flex-row-wrapper footer-list-container">
              <h4 className="footer-header">{t("footer:media")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("common:contact")}
                  </NavLink>
                </li>
              </ul>
              <br></br>
              <h4 className="footer-header">{t("footer:social-media")}</h4>
              <ul className="footer-list">
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:instagram")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:facebook")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:twitter")}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="#" className="list-item-text">
                    {t("footer:youtube")}
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="flex-row-wrapper footer-list">
              <h4 className="footer-header">{t("footer:app-download")}</h4>
              <div className="flex-row-wrapper footer-list-container">
                <img className="app-download-icon" src={GooglePlay} alt="" />
                <img className="app-download-icon" src={AppStore} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-min centered">
        <p className="copy-right">
          &#169; Copyright {current_year} {t("footer:copy-right")}
        </p>
      </div>
    </div>
  );
};

export default footer;
