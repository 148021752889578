import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import history from "../../lib/history";
import ScrollToTop from "../../lib/scroll-to-top";
import { logout, setChooseType } from "../../store/actions";
import Button from "../../components/button";
import SimpleLoader from "../../components/simple-loader";
import ProfilePicture from "./01-profile-picture";
import MerchantDetails from "./merchant/01-merchant-details";
import MerchantPackages from "./merchant/02-merchant-packages";
import Settings from "./merchant/03-profile-settings";
import UserDetails from "./user/01-user-details";
import "./index.css";

const UserProfile = (props) => {
  const { dispatch, loading, chooseType } = props;
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation("common", "profile");

  const merchantSteps = [
    {
      step: 0,
      component: <ProfilePicture />,
      label: t("profile:profile-photo"),
    },
    {
      step: 1,
      component: <MerchantDetails />,
      label: t("profile:contact-details"),
    },
    {
      step: 2,
      component: <MerchantPackages />,
      label: t("profile:user-packages"),
      disabled: true,
    },
    {
      step: 3,
      component: <Settings />,
      label: t("common:settings"),
      disabled: true,
    },
  ];

  const userSteps = [
    {
      step: 0,
      component: <ProfilePicture />,
      label: t("profile:profile-photo"),
    },
    {
      step: 1,
      component: <UserDetails />,
      label: t("profile:contact-details"),
    },
  ];

  const logoutHandler = () => {
    dispatch(logout());
    dispatch(setChooseType(null));
    history.push("/home");
  };

  return (
    <SimpleLoader loading={loading}>
      <ScrollToTop dependency={[activeStep]} />
      <div className="user-profile">
        <div className="aside">
          {chooseType === 1 && (
            <ul>
              {userSteps.map((item) => {
                return (
                  <li
                    className={`profile-stepper ${
                      activeStep === item.step ? "activestep" : "inactivestep"
                    }`}
                    key={item.step}
                    onClick={() => setActiveStep(item.step)}
                  >
                    {item.label}
                  </li>
                );
              })}
            </ul>
          )}
          {chooseType === 2 && (
            <ul>
              {merchantSteps.map((item) => {
                return (
                  <li
                    className={`profile-stepper ${
                      activeStep === item.step ? "activestep" : "inactivestep"
                    }`}
                    key={item.step}
                    onClick={() => setActiveStep(item.step)}
                  >
                    {item.label}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        <div className="main">
          <div className="header">
            <h4>{t("profile:my-car-index-profile")}</h4>
            <Button
              color="white"
              className="logoutbutton"
              label={t("common:logout")}
              click={logoutHandler}
            />
          </div>
          {chooseType === 1 && userSteps[activeStep].component}
          {chooseType === 2 && merchantSteps[activeStep].component}
        </div>
      </div>
    </SimpleLoader>
  );
};

const stateToProps = (state) => {
  const { loading, chooseType } = state.profile;
  return {
    loading,
    chooseType,
  };
};

export default connect(stateToProps)(UserProfile);
