import React, { useLayoutEffect, useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { numberWithCommas, getPropName, kwToPs } from "../../../lib/utils";
import { getConditionForLocale } from "../../../store/actions";
import { useTranslation } from "react-i18next";
import Button from "../../button";
import Image from "../../image";
import { PhotoPlaceholder, Share, Star } from "../../svg-icons/index";
import RegistrationIcon from "../../../assets/images/ad-props-icons/Registration.svg";
import KmIcon from "../../../assets/images/ad-props-icons/Km.svg";
import Power from "../../../assets/images/ad-props-icons/Power.svg";
import Fuel from "../../../assets/images/ad-props-icons/fuel.svg";
import Transsmision from "../../../assets/images/ad-props-icons/transsmision.svg";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

const AdCard = (props) => {
  const {
    fuel_type,
    dispatch,
    idlocale,
    idad,
    photos,
    makename,
    modelname,
    address_data,
    name,
    month,
    year,
    idfueltype,
    price,
    generaldata,
    headline,
    engineenvironment,
    mileage,
    previewAd,
    toggleFavorites,
    favoriteIds,
    transmission,
    idtransmission,
  } = props;
  const { t } = useTranslation(["common"]);
  const NA = "N/A";
  const powerInKW = generaldata?.power;
  const powerInPS = kwToPs(powerInKW);

  const handleShareDeepLinking = async (idAd) => {
    try {
      const response = await axios.post(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.FIREBASE_API_KEY}`,
        {
          dynamicLinkInfo: {
            domainUriPrefix: "https://carindex.page.link",
            link: `https://www.carindex.com/?share=${idAd}`,
            androidInfo: {
              androidPackageName: "com.carindex.user",
            },
            iosInfo: {
              iosBundleId: "com.carindex.user",
            },
          },
        }
      );
      navigator.clipboard.writeText(response?.data?.shortLink ?? "");
      toast.success("Successfully copied to clipboard!");
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    dispatch(getConditionForLocale());
  }, []);

  useEffect(() => {
    dispatch(getConditionForLocale());
  }, [idlocale]);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const window_size = useWindowSize();

  return (
    <>
      {window_size[0] < 1024 ? (
        <div className="base-card-tablet">
          <section>
            <div onClick={() => previewAd(idad)}>
              {photos ? (
                <Image src={photos[0]} className="cover" />
              ) : (
                <PhotoPlaceholder />
              )}
            </div>
            <div>
              <div className="makeandmodel">
                <h5>{makename + " " + modelname}</h5>
                <h5>
                  {generaldata?.equipmentline ?? ""}{" "}
                  {generaldata?.variant ?? ""}
                  {headline ?? ""}
                </h5>
              </div>
              <div>
                <ul>
                  <li>
                    <img src={RegistrationIcon} />
                    {month < 10 ? `0${month}` : month}/{year}
                  </li>
                  <li>
                    <img src={KmIcon} />
                    {mileage ? `${numberWithCommas(mileage)} km` : NA}
                  </li>
                  <li>
                    <img src={Power} />
                    {generaldata?.power ? `${powerInPS} PS` : NA}
                    <span className="power-kw-label">({powerInKW} KW)</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <img src={Fuel} />
                    {fuel_type.length > 0 &&
                      getPropName(fuel_type, idfueltype, "idfueltype")}
                  </li>
                  <li>
                    <img src={Transsmision} />
                    {transmission?.length > 0 &&
                      getPropName(
                        transmission,
                        engineenvironment?.idtransmission,
                        "idtransmission"
                      )}
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div>
              <Button
                label={`CHF ${numberWithCommas(price)}.-`}
                color="white"
                click={() => previewAd(idad)}
              />
              <div>
                <span onClick={() => handleShareDeepLinking(idad)}>
                  <Share color="#969696" />
                </span>

                <Star
                  color={favoriteIds?.includes(idad) ? "#cc2c24" : "#969696"}
                  click={() => toggleFavorites(idad)}
                />
              </div>
            </div>
            <div>
              {address_data ? (
                <>
                  <p className="no-margin">{name}</p>
                  <p className="no-margin">
                    {address_data?.streetandnumber} {address_data?.zipcode}{" "}
                    {address_data?.city} {address_data?.region}
                  </p>
                </>
              ) : (
                <p>
                  {t("profile:name")} & {t("profile:address-location")}
                </p>
              )}
            </div>
          </section>
        </div>
      ) : (
        <div className="base-card">
          <section onClick={() => previewAd(idad)}>
            <div>
              {photos ? (
                <Image src={photos[0]} className="cover" />
              ) : (
                <PhotoPlaceholder />
              )}
            </div>
          </section>
          <section>
            <div>
              <div className="makeandmodel">
                <h5>{makename + " " + modelname}</h5>
                <h5>
                  {generaldata?.equipmentline
                    ? `${generaldata.equipmentline}`
                    : ""}{" "}
                  {generaldata?.variant ? `${generaldata.variant}` : ""}
                  {headline ?? ""}
                </h5>
              </div>
              <div>
                <span onClick={() => handleShareDeepLinking(idad)}>
                  <Share color="#969696" />
                </span>
                <Star
                  color={favoriteIds?.includes(idad) ? "#cc2c24" : "#969696"}
                  click={() => toggleFavorites(idad)}
                />
              </div>
            </div>

            <div>
              <ul>
                <li>
                  <img src={RegistrationIcon} />
                  {month < 10 ? `0${month}` : month} / {year}
                </li>
                <li>
                  <img src={KmIcon} />
                  {mileage ? `${numberWithCommas(mileage)} km` : NA}
                </li>
                <li>
                  <img src={Power} />
                  {generaldata?.power ? `${powerInPS} PS` : NA}
                  <span className="power-kw-label">({powerInKW} KW)</span>
                </li>
              </ul>
              <ul>
                <li>
                  <img src={Fuel} />
                  {fuel_type.length > 0 &&
                    getPropName(fuel_type, idfueltype, "idfueltype")}
                </li>
                <li>
                  <img src={Transsmision} />
                  {transmission?.length > 0 &&
                    getPropName(
                      transmission,
                      engineenvironment?.idtransmission,
                      "idtransmission"
                    )}
                </li>
              </ul>
            </div>
            <div className="card-controls">
              <Button
                label={`CHF ${numberWithCommas(price)}.-`}
                color="white"
                click={() => previewAd(idad)}
              />
              {window_size[0] < 1280 ? (
                <div>
                  {address_data ? (
                    <>
                      <p className="no-margin">{name}</p>
                      <p className="no-margin">
                        {address_data?.streetandnumber} {address_data?.zipcode}{" "}
                        {address_data?.city} {address_data?.region}
                      </p>
                    </>
                  ) : (
                    <p>
                      {t("profile:name")} & {t("profile:address-location")}
                    </p>
                  )}{" "}
                </div>
              ) : (
                <div>
                  <div>
                    {address_data ? (
                      <>
                        <p className="no-margin">{name}</p>
                        <p className="no-margin">
                          {address_data?.streetandnumber}{" "}
                          {address_data?.zipcode} {address_data?.city}{" "}
                          {address_data?.region}
                        </p>
                      </>
                    ) : (
                      <p>
                        {t("profile:name")} & {t("profile:address-location")}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
};

const stateToProps = (state) => {
  const { body_type, fuel_type, idlocale, transmission } = state.car;
  return {
    body_type,
    fuel_type,
    idlocale,
    transmission,
  };
};

export default connect(stateToProps)(AdCard);
