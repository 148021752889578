import React, {  useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { logout } from "../../store/actions.js";

const ProtectedRoute = (props) => {
  const { dispatch, render, route } = props;
  const [token, setToken] = useState(() => localStorage.getItem("token"));
  const email = secureLocalStorage.getItem("email");
  const password = secureLocalStorage.getItem("password");
  const userType = secureLocalStorage.getItem("userType");
  const history = useHistory();
  const [hideRender, setHideRender] = useState(true);

  // useEffect(() => {
  //   if (!(isAuthorized && (Component || render))) {
  //     props.dispatch(setProtectedReferrer(path));
  //     // props.dispatch(restoreAuth());
  //   }
  // }, []);
  useEffect(() => {
    if (email === null || password === null || userType === null) {
      dispatch(logout());
      setToken(null);
      return;
    }
    if (!token) {
      dispatch(logout());
    }
    if (!route?.userTypes?.includes(userType)) {
      history.push("/home");
    }
    setHideRender(false);
  });

  return (
    <Route {...props}>
      {!!token ? (
        hideRender ? null : render
      ) : (
        <Redirect
          to={{ pathname: "/signin", state: { from: location.pathname } }}
        />
      )}
    </Route>
  );
};

export default connect()(ProtectedRoute);
